import { useState, createContext } from "react";

export const SiteContext = createContext({});

export const SiteProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [users, setUsers] = useState();
  const [iglesias, setIglesias] = useState();
  const [usersCedulas, setUserCedulas] = useState();
  const [eventos, setEventos] = useState();
  const [eventData, setEventData] = useState();
  const [lugares, setLugares] = useState();
  const [dormitoriosAsignados, setDormitoriosAsignados] = useState();
  const [tiposDeAsistentes, setTiposDeAsistentes] = useState();
  const [defaultEventID] = useState("4");
  const [eventParticipantsGetter, setEventParticipantsGetter] = useState();

  const deleteLocalInfo = () => {
    setUser();
    setUsers();
    setIglesias();
    setUserCedulas();
    setEventos();
    setEventData();
    setLugares();
    setTiposDeAsistentes();
    setDormitoriosAsignados();
    setEventParticipantsGetter();
  };

  return (
    <SiteContext.Provider
      value={{
        user,
        setUser,
        users,
        setUsers,
        iglesias,
        setIglesias,
        usersCedulas,
        setUserCedulas,
        eventos,
        setEventos,
        eventData,
        setEventData,
        lugares,
        setLugares,
        tiposDeAsistentes,
        setTiposDeAsistentes,
        dormitoriosAsignados,
        setDormitoriosAsignados,
        deleteLocalInfo,
        defaultEventID,
        eventParticipantsGetter,
        setEventParticipantsGetter,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
