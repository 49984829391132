import {
  Check,
  Close,
  KeyboardArrowDown,
  QrCodeScanner,
  Search,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { Container } from "@mui/system";
import { ref, set } from "firebase/database";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import CustomLoading from "../../components/CustomLoading";
import { database } from "../../config";
import { SiteContext } from "../../contexts/SiteContext";
import { beep, dateDiff, genders, MainContent } from "../../utils";
import QrScanner from "qr-scanner";
import { CustomTextField } from "../../components/CustomTextField";

export const CheckIn = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const {
    user,
    eventos,
    users,
    eventData,
    setEventData,
    setEventParticipantsGetter,
  } = useContext(SiteContext);
  const [userUID, setUserUID] = useState();
  const { eventID } = useParams();
  const [showScanner, setShowScanner] = useState(false);
  const [qrInstance, setQrInstance] = useState();
  const defaultMsg = "Escanea una entrada 📷";
  const [scanMessage, setScanMessage] = useState(defaultMsg);

  const updateAsistencia = async (evt, asistencia) => {
    await set(
      ref(database, `users/${userUID}/asistencias/${eventID}`),
      asistencia
    );
  };

  useEffect(() => {
    if (eventos) {
      setEventData(eventos[eventID]);
      setEventParticipantsGetter(eventID);
    }
  }, [eventos, eventID]);

  const showUserData = async (UID) => {
    beep.play();
    navigator.vibrate(250);
    setUserUID(UID);
    setScanMessage(undefined);
    await set(ref(database, `busquedas/${user.uid}/${eventID}`), UID);
  };

  const handleScan = async ({ data: UID }) => {
    if (UID) {
      setLoading(true);
      if (users[UID]) {
        await showUserData(UID);
      } else {
        setScanMessage("Entrada no válida :(");
      }
      setLoading(false);
      setShowScanner(false);
    }
  };

  const searchByCedula = async () => {
    setLoading(true);
    const { cedula } = formData;
    const UID = Object.values(users).find((u) => u.cedula === cedula)?.uid;
    if (UID) {
      await showUserData(UID);
    } else {
      setScanMessage("Entrada no válida :(");
    }
    setLoading(false);
  };

  const deleteSearch = () => {
    set(ref(database, `busquedas/${user.uid}/${eventID}`), null);
    setUserUID(undefined);
    setScanMessage(defaultMsg);
  };

  useEffect(() => {
    if (
      !qrInstance &&
      user &&
      eventData &&
      users &&
      Object.keys(eventData?.participantes || {}).every((uid) => {
        const userList = Object.keys(users);
        return userList.includes(uid);
      })
    ) {
      setLoading(false);
      const qrScanner = new QrScanner(
        document.getElementById("USER_CAMERA"),
        handleScan,
        {
          maxScansPerSecond: 30,
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );
      if (showScanner === true) qrScanner.start();
      setQrInstance(qrScanner);
      console.log("Inicialice el QR Scanner");
    } else if (qrInstance && showScanner === true) {
      qrInstance.start();
    } else if (qrInstance && showScanner === false) {
      qrInstance.stop();
    }
  }, [showScanner, user, eventData, users]);

  return (
    <>
      <Helmet>
        <title>Escaner | {eventData?.nombre || ""}</title>
      </Helmet>
      <CustomLoading show={loading} logo={eventData?.logo} />
      <MainContent header_height={theme.header.height}>
        <div
          style={{
            display: showScanner ? "flex" : "none",
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={(e) => {
            if (e.target !== e.currentTarget) return;
            setShowScanner(false);
          }}
        >
          <video
            id="USER_CAMERA"
            style={{
              borderRadius: "50px",
              width: "85vw",
              height: "85vw",
              maxWidth: "500px",
              maxHeight: "500px",
              objectFit: "cover",
            }}
          />
        </div>
        <div
          style={{
            overflow: "auto",
            padding: "25px 0px 25px 0px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: 50,
              }}
            >
              <CustomTextField
                formData={formData}
                setFormData={setFormData}
                label="Cédula"
                inputMode="numeric"
                placeholder="0987654321"
                maxLength={10}
                suffix={
                  <IconButton
                    onClick={() => setFormData({})}
                    sx={{ marginRight: "-12px" }}
                  >
                    {formData["cedula"] ? <Close /> : <></>}
                  </IconButton>
                }
                leftHelperText="Busqueda por C.I."
              />
              <LoadingButton
                loading={loading}
                size="small"
                variant="contained"
                color="secondary"
                style={{
                  position: "absolute",
                  right: 0,
                  top: 7,
                  height: "40px",
                  width: "40px",
                  maxHeight: "40px",
                  maxWidth: "40px",
                  minWidth: "40px",
                }}
                onClick={searchByCedula}
              >
                <Search />
              </LoadingButton>
            </div>
            <div style={{ position: "relative", marginTop: "5px" }}>
              <div
                style={{
                  position: "absolute",
                  left: -75,
                  top: 55,
                  transform: "rotate(-90deg)",
                }}
              >
                <div className="arrow bounce">
                  <KeyboardArrowDown style={{ fontSize: "50px" }} />
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: -72.5,
                  top: 45,
                  transform: "rotate(90deg)",
                }}
              >
                <div className="arrow bounce">
                  <KeyboardArrowDown style={{ fontSize: "50px" }} />
                </div>
              </div>
              <LoadingButton
                loading={loading}
                variant="contained"
                color="secondary"
                style={{
                  height: "60vw",
                  width: "60vw",
                  maxHeight: "150px",
                  maxWidth: "150px",
                }}
                sx={{
                  ".MuiSvgIcon-root": {
                    fontSize: "120px",
                  },
                }}
                onClick={() => {
                  setShowScanner(true);
                  //getUserData()
                }}
              >
                <QrCodeScanner />
              </LoadingButton>
            </div>

            {scanMessage ? (
              <Typography variant="h3" sx={{ mt: 2 }}>
                {scanMessage}
              </Typography>
            ) : (
              <></>
            )}
          </div>

          {userUID ? (
            <Container maxWidth="sm">
              <Card
                sx={{ textAlign: "center", p: 3, position: "relative" }}
                elevation={10}
              >
                <div style={{ position: "absolute", right: 7.5, top: 7.5 }}>
                  <IconButton onClick={deleteSearch}>
                    <Close />
                  </IconButton>
                </div>
                <Typography variant="h3" sx={{ mb: 1 }}>
                  {`${users[userUID]?.nombres} ${users[userUID]?.apellidos}`}
                </Typography>
                {users[userUID].eventos[eventID] ? (
                  <>
                    <div style={{ margin: "0px 5px 5px 0px" }}>
                      <b>Cédula:</b>
                      <span style={{ marginLeft: "5px" }}>
                        {
                          users[userUID].cedula
                            .split("")
                            .map(
                              (l, i) => l + (i % 4 === 0 ? "-" : "")
                            ) /* Para mejor lectura */
                        }
                      </span>
                    </div>
                    <div style={{ margin: "0px 5px 5px 0px" }}>
                      <b>{users[userUID].correo}</b>
                    </div>
                    <div style={{ margin: "0px 5px 5px 0px" }}>
                      <b>Edad:</b>
                      <span style={{ marginLeft: "5px" }}>
                        {dateDiff(
                          new Date(
                            users[userUID].fecha_de_nacimiento + "T00:00:00.000"
                          )
                        ) +
                          " años - " +
                          genders.find((g) => users[userUID].genero === g.value)
                            .name}
                      </span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <b style={{ marginRight: "3px" }}>Pago:</b>
                      <span>
                        {users[userUID].pagos &&
                        users[userUID].pagos[eventID] === true ? (
                          <Check color="success" />
                        ) : (
                          <Close color="error" />
                        )}
                      </span>
                      <b style={{ marginLeft: "10px", marginRight: "3px" }}>
                        Habitación:
                      </b>
                      <span>
                        {users[userUID].habitaciones &&
                        users[userUID].habitaciones[eventID] ? (
                          <Check color="success" />
                        ) : (
                          <Close color="error" />
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <div style={{ width: "100%", maxWidth: "80%" }}>
                        <Typography variant="h4" sx={{ mb: 1 }}>
                          Asistencia:
                        </Typography>
                        <ToggleButtonGroup
                          fullWidth
                          value={
                            users[userUID].asistencias &&
                            users[userUID].asistencias[eventID]
                              ? users[userUID].asistencias[eventID]
                              : false
                          }
                          exclusive
                          onChange={updateAsistencia}
                        >
                          <ToggleButton value={false}>
                            No&nbsp;
                            <Close />
                          </ToggleButton>
                          <ToggleButton value={true}>
                            Si&nbsp;
                            <Check />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </>
                ) : (
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                  >
                    Este usuario <b>no</b> está registrado en este evento.
                  </Typography>
                )}
              </Card>
            </Container>
          ) : (
            <></>
          )}
        </div>
      </MainContent>
    </>
  );
};
