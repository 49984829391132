import { YouthTogetherTheme } from "./schemes/YouthTogetherTheme";
import { GLuzTheme } from "./schemes/GLuzTheme";
import { ParadoxTheme } from "./schemes/ParadoxTheme";
import { Congreso2023Theme } from "./schemes/Congreso2023Theme";
import { GLuzNavidad2023 } from "./schemes/GLuzNavidad2023";
import { Camp2024Theme } from "./schemes/Camp2024Theme";

export function themeCreator(theme) {
  return themeMap[theme];
}

const themeMap = {
  YouthTogetherTheme,
  ParadoxTheme,
  Congreso2023Theme,
  GLuzTheme,
  GLuzNavidad2023,
  Camp2024Theme,
};
