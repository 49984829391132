import { useContext } from "react";
import Scrollbar from "../../../components/Scrollbar";
import { SidebarContext } from "../../../contexts/SidebarContext";
import { SiteContext } from "../../../contexts/SiteContext";

import {
  Box,
  Drawer,
  styled,
  Divider,
  useTheme,
  Button,
  darken,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../config";

import SidebarMenu from "./SidebarMenu";
import Logo from "../../../components/Logo";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 72px;
`
);

function Sidebar() {
  const { deleteLocalInfo } = useContext(SiteContext);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: theme.sidebar.background,
            color: theme.sidebar.textColor, //darken(theme.colors.alpha.black[100], 0.5),
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52,
                }}
              >
                <Logo />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu />
          </Scrollbar>
          <Divider
            sx={{
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <Box p={2}>
            <Button
              onClick={async () => {
                await auth.signOut();
                deleteLocalInfo();
                //navigate("/login", { replace: true });
              }}
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
            >
              Cerrar Sesión
            </Button>
          </Box>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
