import { ArrowBack, QrCode, WhatsApp } from "@mui/icons-material";
import { onAuthStateChanged } from "firebase/auth";
import { get, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, database } from "../config";
import { getSaludo, getWordGender } from "../utils";
import MessagePage from "./MessagePage";

const ConfirmationPage = () => {
  const { eventID } = useParams();
  const navigate = useNavigate();
  const [messageInfo, setMessageInfo] = useState(null);

  let subscriber;
  useEffect(() => {
    if (!subscriber) {
      subscriber = onAuthStateChanged(auth, async (user) => {
        let message = {};
        if (user) {
          // User is signed in
          //Validamos que esté registrado en el evento
          const data = await get(ref(database, `users/${user.uid}`));
          const isRegistered = data.child(`eventos/${eventID}`).exists();
          const userData = data.val();
          const nombre = userData.nombres.split(" ")[0];
          const cedula = userData.cedula;
          const WA_URL = (
            await get(ref(database, `eventos/${eventID}/whatsAppURL`))
          ).val();
          const wordEnding = getWordGender(userData.genero);
          if (isRegistered) {
            message.image = "Confirmación";
            message.message = getSaludo(nombre, wordEnding);
            message.subMessage = `Ya estás registrad${wordEnding}, para poder confirmar la reserva de tu cupo, ponte en contacto con nosotros y coordina tu pago.`;
            message.buttonText2 = "Ir a WhatsApp";
            message.buttonIcon2 = <WhatsApp />;
            message.buttonAction2 = () => window.open(WA_URL, "_blank");
            message.buttonText = "Revisa tu Entrada";
            message.buttonIcon = <QrCode />;
            message.buttonAction = () => {
              navigate("/login", {
                replace: true,
                state: { redirect: `/home/entradas/${eventID}` },
              });
            };
          } else {
            // User is not registered for this event
            message.image = "Conf. Error";
            message.message = `Que pena ${nombre}...`;
            message.subMessage = `Aún no estas registrad${wordEnding} a este evento, o este evento no existe :(`;
            message.buttonText = "Quiero Registrarme";
            message.buttonIcon = <ArrowBack />;
          }
          setMessageInfo(message);
        } else {
          // User is signed out
          message.image = "Conf. Error";
          message.message = `No hay nada aquí`;
          message.subMessage = `Primero tienes que registrarte...`;
          message.buttonText = "Quiero Registrarme";
          message.buttonIcon = <ArrowBack />;
          setMessageInfo(message);
        }
      });
    }
    return subscriber;
    // eslint-disable-next-line
  }, []);

  return messageInfo ? (
    <MessagePage
      errorCode={messageInfo.image}
      errorMessage={messageInfo.message}
      errorSubMessage={messageInfo.subMessage}
      buttonText={messageInfo.buttonText}
      buttonIcon={messageInfo.buttonIcon}
      buttonAction={messageInfo.buttonAction}
      buttonText2={messageInfo.buttonText2}
      buttonIcon2={messageInfo.buttonIcon2}
      buttonAction2={messageInfo.buttonAction2}
    />
  ) : (
    ""
  );
};

export default ConfirmationPage;
