import {
  Alert,
  Backdrop,
  Card,
  Grid,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import { Container, Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessages, MainContentScreen as MainContent } from "../utils";
import { CustomTextField, isValidForm } from "../components/CustomTextField";
import { Lock, Mail } from "@mui/icons-material";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { auth, database, sendCustomResetPassLink } from "../config";
import { get, ref } from "firebase/database";
import { SiteContext } from "../contexts/SiteContext";
import themeList from "../theme/schemes/Temas";

const Login = () => {
  const { defaultEventID } = useContext(SiteContext);
  const [redirectTo, setRedirectTo] = useState();
  const [formData, setFormData] = useState({
    correo: "",
    contrasena: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("Error");
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  let subscriber;
  useEffect(() => {
    if (!subscriber) {
      subscriber = onAuthStateChanged(auth, async (user) => {
        setIsLoading(true);
        if (user) {
          const data = await get(ref(database, `users/${user.uid}`));
          if (data.exists()) {
            const userData = data.val();
            if (userData.hasUpdatedPass === true) {
              setRedirectTo(location?.state?.redirect || "/home/perfil");
              return;
            } else {
              try {
                sendCustomResetPassLink({
                  correo: user.email,
                  themeName: localStorage.getItem("appTheme"),
                  emailSender:
                    themeList.find(
                      (t) => t.id === localStorage.getItem("appTheme")
                    )?.mailSender ?? "YOUTH",
                });
                setSnackbarMessage(
                  `Hola ${
                    userData.nombres.split(" ")[0]
                  }! Para tu seguridad, tienes que cambiar de contraseña. ${
                    errorMessages[1006]
                  }`
                );
                setSnackbarType("success");
                await auth.signOut();
              } catch (error) {
                setSnackbarMessage(errorMessages[error.code] ?? error.message);
                setSnackbarType("error");
              }
            }
            setOpenSnackbar(true);
          } else {
            //Cerramos la sesión de ese usuario si no existen datos aún (muy poco probable)
            auth.signOut();
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      });
    }
    return subscriber;

    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (redirectTo) {
      //if (typeof subscriber === "function") subscriber();
      navigate(redirectTo, { replace: true });
    }
  }, [redirectTo]);

  const login = async () => {
    try {
      setIsLoading(true);
      isValidForm(formData);
      //Iniciamos sesión (y se ejecutaria el onAuthStateChanged del useEffect)
      await signInWithEmailAndPassword(
        auth,
        formData["correo"],
        formData["contrasena"]
      );
    } catch (error) {
      setSnackbarMessage(errorMessages[error.code] ?? error.message);
      setSnackbarType("error");
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Iniciar Sesión</title>
      </Helmet>
      <div style={{ overflow: "auto", maxHeight: "100%" }}>
        <MainContent>
          <Container maxWidth="md">
            {/* <Box textAlign="center">
              <img
                alt={"Logo"}
                style={{ width: "80%", maxWidth: "350px" }}
                src={`/static/images/status/404.svg`}
              />
            </Box> */}
            <Container maxWidth="sm">
              <Card
                sx={{
                  textAlign: "center",
                  mt: 3,
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                elevation={14}
              >
                <Typography variant="h3" sx={{ mb: 1 }}>
                  Iniciar Sesión
                </Typography>
                <Box
                  component="form"
                  autoComplete="off"
                  padding={1.5}
                  paddingBottom={1}
                >
                  <Grid
                    container
                    columnSpacing={2}
                    style={{ marginBottom: "-20px" }}
                  >
                    <Grid item xs={12}>
                      <CustomTextField
                        lowercase
                        formData={formData}
                        setFormData={setFormData}
                        label="Correo"
                        type="email"
                        autocomplete="email"
                        suffix={<Mail />}
                        placeholder="correo@ejemplo.com"
                        leftHelperText="Ingresa tu Correo Electrónico"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        formData={formData}
                        type="password"
                        autocomplete="password"
                        suffix={<Lock />}
                        setFormData={setFormData}
                        placeholder="*********"
                        label="Contraseña"
                        leftHelperText="¿Primera vez? Escribe tu cédula!"
                        onEnterCallback={login}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <div
                  style={{
                    textAlign: "right",
                    marginRight: "15px",
                    width: "100%",
                  }}
                >
                  <Link
                    onClick={async () => {
                      if (!(formData["correo"] && formData["correo"] !== "")) {
                        setSnackbarType("error");
                        setSnackbarMessage("Por favor, introduce tu correo.");
                        setOpenSnackbar(true);
                        return;
                      } else {
                        setIsLoading(true);
                        try {
                          await sendCustomResetPassLink({
                            correo: formData["correo"],
                            themeName: localStorage.getItem("appTheme"),
                            emailSender:
                              themeList.find(
                                (t) => t.id === localStorage.getItem("appTheme")
                              )?.mailSender ?? "YOUTH",
                          });
                          setSnackbarMessage(`Listo! ${errorMessages[1006]}`);
                          setSnackbarType("success");
                        } catch (error) {
                          setSnackbarMessage(error?.message);
                          setSnackbarType("error");
                        }
                        setOpenSnackbar(true);
                        setIsLoading(false);
                      }
                    }}
                    color="secondary"
                    underline="always"
                    fontSize="12px"
                    style={{ cursor: "pointer" }}
                  >
                    {"No sé mi contraseña 😢"}
                  </Link>
                </div>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={isLoading}
                  style={{ maxWidth: "350px", marginTop: "15px" }}
                  onClick={login}
                >
                  INGRESAR
                </LoadingButton>
                <Link
                  onClick={() => setRedirectTo("/registro/" + defaultEventID)}
                  color="secondary"
                  underline="always"
                  fontSize="12px"
                  style={{ cursor: "pointer", marginTop: "15px" }}
                >
                  {"¡Quiero Registrarme!"}
                </Link>
              </Card>
            </Container>
          </Container>
        </MainContent>
      </div>
      <Backdrop open={openSnackbar} style={{ zIndex: "10" }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={snackbarMessage.length <= 50 ? 1750 : 5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={snackbarType}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Backdrop>
    </>
  );
};

export default Login;
