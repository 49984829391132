import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Backdrop,
  Container,
  Grid,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CustomLoading from "../../components/CustomLoading";
import { isValidForm } from "../../components/CustomTextField";
import {
  defaultFormData,
  FormularioDatos,
  writeUserData,
} from "../../components/FormularioDatos";
import { SiteContext } from "../../contexts/SiteContext";
import { errorMessages, MainContent } from "../../utils";

export const Profile = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(defaultFormData());
  const [validateForm, setValidateForm] = useState(false);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("Error");
  const { user, iglesias, usersCedulas, eventData } = useContext(SiteContext);

  useEffect(() => {
    setLoading(true);
    if (user && iglesias && usersCedulas) {
      setFormData({ ...user });
      setLoading(false);
    }
  }, [user, iglesias, usersCedulas]);

  const actualizarDatos = async () => {
    try {
      isValidForm(
        formData,
        Object.values(usersCedulas).filter((c) => c !== user.correo),
        undefined,
        eventData
      );
      await writeUserData(formData, user.uid, undefined, undefined, false);
      //Todo bien, se actualizó su data en firebase
      setSnackbarMessage("Datos Actualizados Correctamente!");
      setSnackbarType("success");
    } catch (error) {
      console.log("codigo error", error.code);
      setSnackbarMessage(errorMessages[error.code] ?? error.message);
      setSnackbarType("error");
    } finally {
      setIsLoadingSend(false);
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <CustomLoading show={loading} />
      <MainContent header_height={theme.header.height}>
        <div
          style={{
            overflow: "auto",
            maxHeight: "100%",
            width: "100%",
            padding: "15px",
          }}
        >
          <Container maxWidth="sm" style={{ paddingBottom: "20px" }}>
            <Grid container padding={1} paddingBottom={2} rowGap={0.25}>
              <Grid item xs={12} textAlign={"center"}>
                <Typography
                  variant="h1"
                  color={"secondary"}
                  style={{
                    fontFamily: "Bebas Neue",
                    letterSpacing: "5px",
                    fontSize: "50px",
                  }}
                >
                  Tus Datos
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  fontSize={"15px"}
                  display="flex"
                  justifyContent="center"
                >
                  ¡Mantenlos siempre actualizados!
                </Typography>
              </Grid>
            </Grid>
            <FormularioDatos
              eventData={eventData}
              formData={formData}
              setFormData={setFormData}
              validateForm={validateForm}
              iglesias={iglesias}
              disabledFields={["cedula", "nombres", "apellidos", "genero"]}
              footer={
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <LoadingButton
                      disabled={
                        JSON.stringify(formData) === JSON.stringify(user)
                      }
                      fullWidth
                      variant="contained"
                      style={{ border: "0px" }}
                      color="secondary"
                      loading={isLoadingSend}
                      onClick={async () => {
                        setValidateForm(true);
                        setIsLoadingSend(true);
                        actualizarDatos();
                      }}
                    >
                      ACTUALIZAR DATOS
                    </LoadingButton>
                  </Grid>
                </>
              }
            />
          </Container>
        </div>
      </MainContent>
      <Backdrop open={openSnackbar} style={{ zIndex: "10" }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={snackbarMessage.length <= 50 ? 1750 : 5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarType}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Backdrop>
    </>
  );
};
