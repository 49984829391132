import { alpha, CircularProgress, useTheme } from "@mui/material";
import React, { useState } from "react";

export const CustomImage = (props) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const theme = useTheme();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: alpha(theme.colors.alpha.white[100], 0.65),
        position: "relative",
        ...props?.style,
        margin: props?.margin,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
        }}
      >
        {!hasLoaded ? <CircularProgress thickness={7.5} size={20} /> : <></>}
      </div>
      <img
        alt={props?.alt || "Imagen"}
        src={props.src}
        onLoad={() => setHasLoaded(true)}
        style={{
          ...props?.style,
          display: hasLoaded ? "block" : "none",
        }}
      />
    </div>
  );
};
