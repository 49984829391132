// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import { es } from "date-fns/locale";
import { setDefaultOptions } from "date-fns";
import { getStorage } from "firebase/storage";
setDefaultOptions({ locale: es });

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyAL1pTeCyWFm_5nUwiGAbD87YGBhARyD2M",
  authDomain: "gluzsite.firebaseapp.com",
  projectId: "gluzsite",
  storageBucket: "gluzsite.appspot.com",
  messagingSenderId: "1003201878270",
  appId: "1:1003201878270:web:5fb75cb7b2e40abfbeedaf",
  databaseURL: "https://gluzsite-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const storage = getStorage(app);
const functions = getFunctions(app);
export const sendCustomResetPassLink = httpsCallable(
  functions,
  "auth-sendResetPassLink"
);
export const registerNewUser = httpsCallable(functions, "auth-registerNewUser");
export const resendConfirmation = httpsCallable(
  functions,
  "auth-resendConfirmation"
);
