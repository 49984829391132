import { alpha, Backdrop, useTheme } from "@mui/material";
import React from "react";
import styled from "styled-components";

const AnimationWrapper = styled.div`
  @keyframes loading-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-animation > div > div {
    transform-origin: calc(100px * var(--loading-factor))
      calc(100px * var(--loading-factor));
    animation: loading-animation 3.0303030303030303s linear infinite;
    opacity: 0.8;
  }
  .loading-animation > div > div > div {
    position: absolute;
    left: calc(30px * var(--loading-factor));
    top: calc(30px * var(--loading-factor));
    width: calc(70px * var(--loading-factor));
    height: calc(70px * var(--loading-factor));
    border-radius: calc(70px * var(--loading-factor)) 0 0 0;
    transform-origin: calc(100px * var(--loading-factor))
      calc(100px * var(--loading-factor));
  }
  .loading-animation > div div:nth-child(1) {
    animation-duration: 0.7575757575757576s;
  }
  .loading-animation > div div:nth-child(1) > div {
    background: ${(props) => props.colors[0]};
    transform: rotate(0deg);
  }
  .loading-animation > div div:nth-child(2) {
    animation-duration: 1.0101010101010102s;
  }
  .loading-animation > div div:nth-child(2) > div {
    background: ${(props) => props.colors[1]};
    transform: rotate(0deg);
  }
  .loading-animation > div div:nth-child(3) {
    animation-duration: 1.5151515151515151s;
  }
  .loading-animation > div div:nth-child(3) > div {
    background: ${(props) => props.colors[2]};
    transform: rotate(0deg);
  }
  .loading-animation > div div:nth-child(4) {
    animation-duration: 3.0303030303030303s;
  }
  .loading-animation > div div:nth-child(4) > div {
    background: ${(props) => props.colors[3]};
    transform: rotate(0deg);
  }
  .loadingio-spinner {
    width: calc(200px * var(--loading-factor));
    height: calc(200px * var(--loading-factor));
    display: inline-block;
    overflow: hidden;
  }
  .loading-animation {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .loading-animation div {
    box-sizing: content-box;
  }
`;

const CustomLoading = ({ show = true, logo = "" }) => {
  const theme = useTheme();
  return (
    show && (
      <AnimationWrapper
        colors={[
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.terciary.main,
          theme.palette.primary.main,
        ]}
      >
        <Backdrop
          open={true}
          style={{
            zIndex: "100000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: alpha(theme.colors.alpha.white[100], 0.65),
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt=""
              style={{
                minWidth: "25%",
                width: "300px",
                maxWidth: "75%",
                margin: "auto",
                display: "block",
              }}
              className="element-not-drag animate-scale"
            />
            <div className="loadingio-spinner">
              <div className="loading-animation">
                <div>
                  <div>
                    <div></div>
                  </div>
                  <div>
                    <div></div>
                  </div>
                  <div>
                    <div></div>
                  </div>
                  <div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Backdrop>
      </AnimationWrapper>
    )
  );
};

export default CustomLoading;
