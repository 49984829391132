import { useTheme } from "@mui/styles";
import { useContext, useEffect, useState } from "react";
import CustomLoading from "../../components/CustomLoading";
import { SiteContext } from "../../contexts/SiteContext";
import {
  MainContent,
  closeSnackbar,
  currencyFormatter,
  dateDiff,
  filterDropdown,
  getWANumber,
  getWordGender,
  gridButton,
} from "../../utils";
import {
  addYears,
  differenceInYears,
  format,
  formatDistanceToNowStrict,
} from "date-fns";
import { useParams } from "react-router-dom";
import {
  Add,
  Check,
  Close,
  FileUpload,
  FilterAlt,
  FilterAltOff,
  RemoveRedEye,
  VisibilityOff,
} from "@mui/icons-material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  esES,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { LoadingButton } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { push, ref } from "firebase/database";
import { database, storage } from "../../config";
import { ref as ajRef, uploadBytes } from "firebase/storage";

const SUPPORT_NUMBER = "+593 98 271 0991";
// MISMOS QUE EN BOT Y BACK-END
const cuentaAbonos =
  "```-``` *Banco de Guayaquil (BG) 🏦*\n" +
  "```--``` _Katherine Encalada_\n" +
  "```--``` *#* 0018584616 (Cta. Ahorros)\n" +
  "```--``` *Cédula:* 0930486394\n" +
  "```--``` " +
  `*Celular:* ${SUPPORT_NUMBER}\n` +
  "```--``` *Correo:* youthtogetherec@gmail.com\n\n" +
  //`También puedes usar tu *tarjeta de débito/crédito*, para obtener más información comunicate a este número: *${SUPPORT_NUMBER}*\n\n` +
  `Recuerda enviar el comprobante de tu pago a este número: *${SUPPORT_NUMBER}*`;

const inviteMessage =
  "{saludo} {nombre}! {emojiSaludo}\n\n" +
  "*- ENDLESS -*\n_Campamento de Jóvenes_\n```Youth Together 2024```\n\n" +
  "Últimos días para inscribirte al campamento {coolEmoji}\n\n" +
  "1. El precio completo del campamento es de *$75*\n" +
  //"2. Tenemos una promoción de *$70* vigente hasta el *3 de Marzo* {celebraEmoji}\n" +
  "2. El campamento será del *29 al 31 de Marzo*, en *Manglaralto* {beachEmoji}\n" +
  "3. Una vez inscrito debes realizar un abono para separar tu cupo.\n\n" +
  "Te puedes inscribir en el siguiente link:\n" +
  "https://jovenes.iglesiabautistaisrael.com/registro/4\n\n" +
  "Te adjunto los datos de la cuenta para que puedas realizar tus abonos:\n" +
  cuentaAbonos +
  "\n\n" +
  "¡{fraseSeeYou}! {emojiSaludo2}";

export const Comunicados = () => {
  const theme = useTheme();
  const { eventID } = useParams();
  const [loading, setLoading] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [listType, setListType] = useState("event");
  const [listTypes] = useState([
    { id: "all", nombre: "Todos" },
    { id: "event", nombre: "Inscritos" },
    { id: "no_event", nombre: "No inscritos" },
    { id: "none", nombre: "Ninguno" },
  ]);
  const {
    eventData,
    tiposDeAsistentes,
    iglesias,
    user,
    users,
    eventos,
    setEventData,
    setEventParticipantsGetter,
  } = useContext(SiteContext);
  const gridRef = useGridApiRef();
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [mensajeWA, setMensajeWA] = useState(inviteMessage);
  const [messageVisibility, setMessageVisibility] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState();
  const [adjunto, setAdjunto] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [modalConfQuestion, setModalConfQuestion] = useState("");
  const [modalConfAction, setModalConfAction] = useState(() => () => {});
  const [fileInput] = useState(() => document.createElement("input"));
  const maxFileSize = 1024 * 1024 * 35; // 35MB

  const fileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= maxFileSize) setAdjunto(file);
    else
      setSnackbar({
        severity: "error",
        children: `El archivo seleccionado excede el tamaño máximo permitido (${(
          maxFileSize /
          (1024 * 1024)
        ).toFixed(0)}MB)`,
      });
  };

  useEffect(() => {
    fileInput.style.display = "none";
    fileInput.type = "file";
    fileInput.multiple = false;
    fileInput.onchange = fileUpload;
  }, [fileInput]);

  const gridButtonCall = (params) => {
    return gridButton(params, tiposDeAsistentes);
  };

  const handleCloseSnackbar = (evt, reason) => {
    closeSnackbar(evt, reason, setSnackbar);
  };

  const columns = [
    {
      headerName: "ID del Usuario",
      field: "uid",
      align: "center",
      type: "string",
      minWidth: 100,
      flex: 1,
      hide: true,
    },
    {
      headerName: "Asistencia",
      field: "asistencia",
      hide: true,
      align: "center",
      minWidth: 90,
      excelWidth: 20,
      type: "boolean",
      valueFormatter: (params) => {
        return params.value ? "Sí" : "No";
      },
      renderCell: (params) =>
        params.value ? <Check color="success" /> : <Close color="error" />,
      flex: 1,
    },
    {
      headerName: "Consejería",
      field: "consejeria",
      hide: true,
      align: "center",
      minWidth: 90,
      excelWidth: 20,
      type: "boolean",
      valueFormatter: (params) => {
        return params.value ? "Sí" : "No";
      },
      renderCell: (params) =>
        params.value ? <Check color="success" /> : <Close color="error" />,
      flex: 1,
    },
    {
      headerName: "Tipo",
      field: "tipo",
      align: "center",
      minWidth: 80,
      excelWidth: 20,
      type: "singleSelect",
      valueOptions: Object.keys(tiposDeAsistentes || {}).map((k) => {
        return {
          value: k.toString(),
          label: tiposDeAsistentes[k],
        };
      }),
      valueFormatter: (params) => {
        return (tiposDeAsistentes || {})[params.value];
      },
      renderCell: gridButtonCall,
      filterOperators: filterDropdown,
      flex: 1,
    },
    {
      headerName: "Pagado",
      field: "pagado",
      align: "center",
      minWidth: 100,
      excelWidth: 20,
      type: "boolean",
      valueFormatter: (params) => {
        return params.value ? "Sí" : "No";
      },
      renderCell: (params) =>
        params.value ? <Check color="success" /> : <Close color="error" />,
      flex: 1,
    },
    {
      headerName: "Abono",
      field: "abono",
      align: "center",
      minWidth: 100,
      excelWidth: 15,
      totalsRowFunction: "sum",
      style: {
        numFmt: '_ $* #,##0.00 _ ;[Red] $* (#,##0.00)_ ;_ $* " - "?? _ ;_ @_ ',
      },
      type: "number",
      valueFormatter: ({ value }) =>
        currencyFormatter.format(value).replace("$", "$ "),
      flex: 1,
    },
    {
      headerName: "Observación",
      field: "observacion",
      valueFormatter: ({ value }) => (value && value !== "" ? value : "-"),
      renderCell: ({ value }) => (
        <Tooltip
          arrow
          placement="right"
          title={value === "-" || value.length <= 19 ? "" : value}
        >
          <span>
            {value.substring(0, 19) + (value.length > 19 ? "..." : "")}
          </span>
        </Tooltip>
      ),
      align: "center",
      minWidth: 150,
      excelWidth: 31.5,
      flex: 3,
    },
    {
      headerName: "Nombres",
      field: "nombres",
      align: "right",
      minWidth: 160,
      excelWidth: 19,
      style: { alignment: { vertical: "middle", horizontal: "right" } },
      flex: 2,
    },
    {
      headerName: "Apellidos",
      field: "apellidos",
      minWidth: 160,
      excelWidth: 19,
      style: { alignment: { vertical: "middle", horizontal: "left" } },
      flex: 2,
    },
    {
      headerName: "Género",
      field: "genero",
      align: "center",
      minWidth: 75,
      excelWidth: 13,
      renderCell: gridButtonCall,
      flex: 1,
    },
    {
      headerName: "Next B-Day",
      field: "nextBday",
      align: "center",
      type: "date",
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "Sin registro";
        }
        return "Dentro de " + formatDistanceToNowStrict(params.value);
      },
      renderCell: (params) =>
        params.value ? (
          <Tooltip
            arrow
            placement="right"
            title={format(params.row.fecha_de_nacimiento, "d 'de' MMM',' yyyy")}
          >
            <span>
              {"Dentro de " + formatDistanceToNowStrict(params.value)}
            </span>
          </Tooltip>
        ) : (
          <span>-</span>
        ),
      editable: false,
      flex: 2,
      hide: true,
    },
    {
      headerName: "Edad",
      field: "edad",
      align: "center",
      type: "number",
      minWidth: 100,
      excelWidth: 12,
      valueFormatter: ({ value: edad }) => `${edad} años`,
      editable: false,
      flex: 1,
    },
    {
      headerName: "Dirección",
      field: "direccion",
      align: "center",
      minWidth: 150,
      excelWidth: 25,
      style: { alignment: { vertical: "middle", horizontal: "center" } },
      flex: 1,
      valueFormatter: ({ value }) => (value?.length > 0 ? value : "-"),
      renderCell: ({ value }) => (
        <Tooltip
          arrow
          placement="left"
          title={value?.length <= 19 ? "" : value}
        >
          <span>
            {value?.substring(0, 19) + (value?.length > 19 ? "..." : "")}
          </span>
        </Tooltip>
      ),
    },
    {
      headerName: "Celular",
      field: "celular",
      align: "center",
      type: "string",
      renderCell: gridButtonCall,
      minWidth: 75,
      excelWidth: 15,
      flex: 1,
    },
    {
      headerName: "Correo",
      field: "correo",
      align: "center",
      type: "string",
      minWidth: 75,
      excelWidth: 33.5,
      renderCell: gridButtonCall,
      flex: 1,
      hide: true,
    },
    {
      headerName: "TikTok",
      field: "tiktok",
      align: "center",
      minWidth: 75,
      excelWidth: 20,
      hide: true,
      renderCell: gridButtonCall,
      flex: 1,
    },
    {
      headerName: "Instagr.",
      field: "instagram",
      align: "center",
      minWidth: 75,
      excelWidth: 18,
      hide: true,
      renderCell: gridButtonCall,
      flex: 1,
    },
    {
      headerName: "Iglesia",
      field: "iglesia",
      align: "center",
      type: "singleSelect",
      valueOptions: Object.keys(iglesias || {}).map((k) => {
        return { value: k.toString(), label: iglesias[k] };
      }),
      valueFormatter: (params) => {
        return (
          (iglesias || {})[params.value] +
          (params.value === "7"
            ? ` (${
                participants.find((p) => p.uid === params.id)[
                  "nombre_de_la_iglesia"
                ]
              })`
            : "")
        );
      },
      renderCell: (params) => {
        const opcion = (iglesias || {})[params.value];
        const otra = participants.find((p) => p.uid === params.id)[
          "nombre_de_la_iglesia"
        ];
        const txt = opcion + (params.value === "7" ? ` (${otra})` : "");
        const max = 25;
        const celda = (params.value === "7" ? otra : opcion) || "";
        return (
          <Tooltip
            arrow
            title={celda.length > max ? txt : ""}
            placement={"left"}
          >
            <span>
              {celda.substring(0, max) + (celda.length > max ? "..." : "")}
            </span>
          </Tooltip>
        );
      },
      filterOperators: filterDropdown,
      minWidth: 175,
      excelWidth: 30,
      flex: 3,
    },
    {
      headerName: "F. Inscripción",
      field: "fecha_de_inscripcion",
      align: "center",
      type: "date",
      minWidth: 130,
      excelWidth: 17,
      style: { numFmt: "dd/mm/yyyy" },
      valueFormatter: ({ value: finscrip }) => {
        if (finscrip == null) {
          return "-";
        }
        return finscrip;
      },
      renderCell: ({ value: finscrip }) => {
        if (!finscrip) {
          return "-";
        }
        return (
          <Tooltip
            arrow
            title={format(finscrip, "dd 'de' MMM 'a las' HH:mm")}
            placement={"left"}
          >
            <span>
              {formatDistanceToNowStrict(finscrip, { addSuffix: true })}
            </span>
          </Tooltip>
        );
      },
      editable: false,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acción",
      width: 75,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <Tooltip
            PopperProps={{ disablePortal: true }}
            title={"Eliminar del Comunicado"}
            placement={"left"}
            arrow
          >
            <GridActionsCellItem
              icon={<Close />}
              label="Eliminar del Comunicado"
              onClick={() =>
                setParticipants(participants.filter((p) => p.uid !== id))
              }
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  const uploadFile = (e) => {
    if (!adjunto) fileInput.click();
  };

  const getPersonalizedUserData = (data) => {
    const fnac = new Date(
      (data?.fecha_de_nacimiento?.substring(0, 10) || "2000-01-01") +
        "T00:00:00.000"
    );
    return {
      ...data,
      pagado: data?.pagos ? data.pagos[eventID] || false : false,
      observacion: data?.observaciones ? data.observaciones[eventID] || "" : "",
      asistencia: data?.asistencias
        ? data.asistencias[eventID] || false
        : false,
      consejeria: data?.consejerias
        ? data.consejerias[eventID] || false
        : false,
      abono: data?.abonos ? data.abonos[eventID] || 0 : 0,
      fecha_de_inscripcion:
        data?.fechas_de_inscripcion && data.fechas_de_inscripcion[eventID]
          ? new Date(data.fechas_de_inscripcion[eventID])
          : undefined,
      tipo: data?.eventos ? data.eventos[eventID] : "0",
      fecha_de_nacimiento: fnac,
      edad: dateDiff(fnac),
      nextBday: addYears(fnac, differenceInYears(new Date(), fnac) + 1),
    };
  };

  const loadParticipants = async (update = true) => {
    if (
      update &&
      eventos &&
      Object.keys(eventos).length > 0 &&
      listType &&
      eventData &&
      tiposDeAsistentes &&
      users &&
      Object.keys(eventos).every((evID) =>
        Object.keys(eventos[evID]?.participantes || {}).every((uid) => {
          const userList = Object.keys(users);
          return userList.includes(uid);
        })
      )
    ) {
      setLoading(true);
      const uids =
        listType === "no_event"
          ? Object.keys(users).filter((uid) => !eventData?.participantes[uid])
          : Object.keys(
              listType === "event"
                ? eventData?.participantes || {}
                : listType === "all"
                ? users
                : {}
            );

      const listado = [];
      //Buscamos los datos de cada usuario
      for (const uid of uids) {
        const curr = users[uid];
        const userData = getPersonalizedUserData(curr);
        listado.push(userData);
      }
      setParticipants(listado);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadParticipants(participants.length === 0);
    // eslint-disable-next-line
  }, [eventData, users, tiposDeAsistentes, eventos]);

  useEffect(loadParticipants, [listType]);

  useEffect(() => {
    if (eventos) {
      setEventData(eventos[eventID]);
      setEventParticipantsGetter("ALL");
    }
  }, [eventos, eventID]);

  const canSend = () =>
    (mensajeWA !== "" || adjunto) &&
    participants.length > 0 &&
    !messageVisibility;

  return (
    <>
      <Helmet>
        <title>Comunicados | {eventData?.nombre || ""}</title>
      </Helmet>
      <CustomLoading show={loading} />
      <MainContent header_height={theme.header.height}>
        <Container maxWidth="xl">
          <Card
            sx={{ textAlign: "center", pl: 2, pr: 2 }}
            elevation={14}
            style={{
              overflow: "auto",
              height: `calc(100vh - ${theme.header.height} - 45px)`,
            }}
          >
            <div
              style={{
                height: "50px",
                marginTop: "15px",
                width: "100%",
                display: "flex",
              }}
            >
              <TextField
                label="Mensaje de WhatsApp"
                variant="outlined"
                multiline
                fullWidth
                maxRows={messageVisibility ? undefined : 1}
                value={mensajeWA}
                onChange={(e) => setMensajeWA(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{ color: "inherit" }}
                      position="end"
                      onClick={() => setMessageVisibility(!messageVisibility)}
                    >
                      <IconButton sx={{ marginRight: "-10px" }}>
                        {!messageVisibility ? (
                          <RemoveRedEye />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                type="text"
                label="Adjuntar Archivo (Max 1)"
                variant="outlined"
                style={{ width: "300px", marginLeft: "10px" }}
                value={adjunto?.name || ""}
                onClick={uploadFile}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{ color: "inherit" }}
                      position="end"
                      onClick={adjunto ? () => setAdjunto() : undefined}
                    >
                      <IconButton sx={{ marginRight: "-10px" }}>
                        {adjunto ? <Close /> : <FileUpload />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                variant="contained"
                color="secondary"
                style={{ marginLeft: "10px" }}
                loading={isLoadingSend}
                disabled={!canSend()}
                onClick={
                  canSend()
                    ? async () => {
                        const doIt = async () => {
                          setIsLoadingSend(true);
                          let adjuntoRef = null;
                          if (adjunto && adjunto.size <= maxFileSize) {
                            const storageRef = ajRef(
                              storage,
                              `comunicados/${user.uid}/${format(
                                new Date(),
                                "yyyyMMddHHmmss"
                              )}---${adjunto.name}`
                            );
                            const adjRef = await uploadBytes(
                              storageRef,
                              adjunto,
                              { contentType: adjunto.type }
                            );
                            adjuntoRef = adjRef.ref.fullPath;
                          }
                          await push(ref(database, "notificaciones"), {
                            destinos: participants.map((p) => ({
                              whatsappID: getWANumber(p.celular),
                              userID: p.uid,
                              enviado: false,
                            })),
                            mensaje: mensajeWA,
                            adjunto: adjuntoRef,
                            enviado: false,
                          });
                          setIsLoadingSend(false);
                          setSnackbar({
                            severity: "success",
                            children: `Mensaje${
                              participants.length > 1 ? "s" : ""
                            } enviado${
                              participants.length > 1 ? "s" : ""
                            } exitosamente`,
                          });
                        };
                        const total = participants.length;
                        setModalConfQuestion(
                          `¿Estás segur${getWordGender(
                            user?.genero ?? "M"
                          )} que deseas enviar [${total}] mensaje${
                            total > 1 ? "s" : ""
                          }? (CUIDADO, ESTA ACCIÓN NO SE PUEDE DESHACER ⚠️)`
                        );
                        setModalConfAction(() => doIt);
                        setOpenModal(true);
                      }
                    : undefined
                }
              >
                Enviar
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  style={{ marginLeft: "5px" }}
                />
              </LoadingButton>
            </div>
            {!messageVisibility && (
              <DataGrid
                apiRef={gridRef}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{
                  Toolbar: () => {
                    const apiRef = useGridApiContext();
                    return (
                      <GridToolbarContainer>
                        <GridToolbarColumnsButton />
                        <ToggleButtonGroup value={listType} size="small">
                          {listTypes.map((btn) => (
                            <ToggleButton
                              style={{ padding: "5px 12.5px" }}
                              key={btn.id}
                              value={btn.id}
                              onClick={() => setListType(btn.id)}
                            >
                              {btn.nombre}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                        {users && (
                          <Autocomplete
                            style={{ width: "375px", marginLeft: "15px" }}
                            clearOnEscape
                            ListboxProps={{
                              style: {
                                height: "200px",
                              },
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.uid === value.uid
                            }
                            value={autoCompleteOptions}
                            onChange={(event, newValue) => {
                              setAutoCompleteOptions(newValue);
                            }}
                            renderOption={(props, option, { inputValue }) => {
                              const matches = match(option.label, inputValue, {
                                insideWords: true,
                              });
                              const parts = parse(option.label, matches);
                              return (
                                <li {...props}>
                                  <div>
                                    {parts.map((part, index) => (
                                      <span
                                        key={index}
                                        style={{
                                          fontWeight: part.highlight
                                            ? 700
                                            : 400,
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}
                                  </div>
                                </li>
                              );
                            }}
                            options={Object.keys(users ?? {})
                              .filter(
                                (uid) =>
                                  !participants.map((p) => p.uid).includes(uid)
                              )
                              .map((uid) => ({
                                uid,
                                label:
                                  users[uid].nombres +
                                  " " +
                                  users[uid].apellidos +
                                  ` - ${dateDiff(
                                    new Date(
                                      users[uid]?.fecha_de_nacimiento +
                                        "T00:00:00.000"
                                    )
                                  )} años`,
                              }))
                              .sort((a, b) =>
                                a.label.toLowerCase() > b.label.toLowerCase()
                                  ? 1
                                  : -1
                              )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                placeholder="Agregar Destinatario..."
                              />
                            )}
                          />
                        )}
                        <IconButton
                          size="small"
                          color="secondary"
                          sx={{
                            marginLeft: "5px",
                          }}
                          onClick={() => {
                            if (autoCompleteOptions) {
                              setParticipants([
                                ...participants,
                                getPersonalizedUserData(
                                  users[autoCompleteOptions.uid]
                                ),
                              ]);
                              setAutoCompleteOptions();
                            }
                          }}
                        >
                          <Add />
                        </IconButton>
                        <Tooltip title="Eliminar Filtrados" arrow>
                          <IconButton
                            size="large"
                            color="error"
                            sx={{
                              marginLeft: "5px",
                            }}
                            onClick={() => {
                              // If the dataGrid has a filter, we need to remove the visible filtered rows
                              const visibleRows =
                                apiRef.current.getVisibleRowModels();
                              setParticipants(
                                participants.filter(
                                  (p) => !visibleRows.get(p.uid)
                                )
                              );
                              // Delete the filter
                              apiRef.current.setFilterModel({ items: [] });
                            }}
                          >
                            <FilterAltOff />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Aplicar Filtro" arrow>
                          <IconButton
                            size="large"
                            color="success"
                            sx={{
                              marginLeft: "5px",
                            }}
                            onClick={() => {
                              // If the dataGrid has a filter, we need to remove the non visible filtered rows
                              const visibleRows =
                                apiRef.current.getVisibleRowModels();
                              setParticipants(
                                participants.filter((p) =>
                                  visibleRows.get(p.uid)
                                )
                              );
                              // Delete the filter
                              apiRef.current.setFilterModel({ items: [] });
                            }}
                          >
                            <FilterAlt />
                          </IconButton>
                        </Tooltip>
                        <GridToolbarQuickFilter
                          id="quickFilterListado"
                          //debounceMs={0}
                          style={{ marginLeft: "auto" }}
                          variant="outlined"
                          placeholder="Buscar..."
                          size="small"
                          onKeyDown={(event) => {
                            setTimeout(() => {
                              document
                                .getElementById("quickFilterListado")
                                .focus();
                            }, 550);
                          }}
                        />
                      </GridToolbarContainer>
                    );
                  },
                }}
                density={"compact"}
                style={{
                  height: "calc(100% - 70px)",
                }}
                headerHeight={60}
                onStateChange={(state) => {
                  localStorage.setItem(
                    "SAVED_COLS_COMUNICADOS",
                    JSON.stringify(state.columns.columnVisibilityModel)
                  );
                  const visibleRows = state.filter.visibleRowsLookup;
                  let visibleItems = [];
                  for (const [id, value] of Object.entries(visibleRows)) {
                    if (value === true) {
                      visibleItems.push(id);
                    }
                  }
                }}
                sx={{
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: theme.colors.alpha.black[10],
                    // color: "red"
                  },
                  "& .app-theme--header": {
                    backgroundColor: "primary.main",
                    color: "white",
                  },
                  "& .app-theme--header .MuiIconButton-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-toolbarContainer": {
                    "& > :not(.MuiFormControl-root)": {
                      [theme.breakpoints.down("md")]: {
                        display: "none",
                      },
                    },
                    "& .MuiFormControl-root": {
                      [theme.breakpoints.down("md")]: {
                        width: "100%",
                      },
                    },
                  },
                }}
                getRowId={(row) => row.uid}
                columns={columns.map((c) => {
                  const prevSavedCols = localStorage.getItem(
                    "SAVED_COLS_COMUNICADOS"
                  );
                  return {
                    ...c,
                    headerAlign: "center",
                    headerClassName: "app-theme--header",
                    hide:
                      prevSavedCols !== null
                        ? !JSON.parse(prevSavedCols)[c.field]
                        : c?.hide || false,
                  };
                })}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "fecha_de_inscripcion", sort: "asc" }],
                  },
                }}
                rows={participants}
                disableSelectionOnClick
              />
            )}
          </Card>
        </Container>
      </MainContent>
      <Dialog
        open={openModal}
        PaperProps={{
          elevation: 5,
          style: { width: "90vw", maxWidth: "500px" },
        }}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontFamily: "Bebas Neue",
            letterSpacing: "2.5px",
            paddingBottom: "0px",
          }}
        >
          Confirmación
        </DialogTitle>
        <DialogContent style={{ paddingBottom: "0px" }}>
          <Grid container paddingY={1.5} paddingTop={1} rowGap={3} columns={12}>
            <Grid item xs={12} textAlign={"center"}>
              {modalConfQuestion}
            </Grid>
            <Grid item xs={6} display={"flex"}>
              <Button
                color={"error"}
                onClick={() => setOpenModal(false)}
                variant="outlined"
                style={{ marginLeft: "auto", marginRight: "10px" }}
              >
                No, cancelar
                <Close style={{ marginLeft: "5px" }} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color={"secondary"}
                onClick={() => {
                  setOpenModal(false);
                  modalConfAction();
                }}
                variant="contained"
              >
                Sí, segur{getWordGender(user?.genero ?? "M")}
                <Check style={{ marginLeft: "5px" }} />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};
