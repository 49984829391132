import { useContext } from "react";

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import { SiteContext } from "../../../../contexts/SiteContext";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import {
  CalendarMonthTwoTone,
  CalendarTodayTwoTone,
  ChurchTwoTone,
  HotelTwoTone,
  ListAltTwoTone,
  LocationOnTwoTone,
  QrCodeScannerTwoTone,
  QrCodeTwoTone,
  WhatsApp,
} from "@mui/icons-material";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  "transform",
                  "opacity",
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const {
    eventos = {},
    user,
    lugares = {},
    defaultEventID,
  } = useContext(SiteContext);

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/home/perfil"
                  end
                  startIcon={<AccountCircleTwoToneIcon />}
                >
                  Perfil
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              {eventos[user?.lastEventID || defaultEventID]?.nombre}
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={`/home/entradas/${user?.lastEventID || defaultEventID}`} //A futuro hacer un padre "Entradas" donde salgan entradas (UX bonito) de acuerdo al numero de eventos inscritos
                  end
                  startIcon={<QrCodeTwoTone />}
                >
                  Entrada
                </Button>
              </ListItem>
              {lugares[eventos[user?.lastEventID || defaultEventID]?.lugar] &&
              lugares[eventos[user?.lastEventID || defaultEventID].lugar]
                .habitaciones ? (
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={`/home/habitaciones/${
                      user?.lastEventID || defaultEventID
                    }`}
                    end
                    startIcon={<HotelTwoTone />}
                  >
                    Dormitorios
                  </Button>
                </ListItem>
              ) : (
                <></>
              )}
              {user?.rol === "admin" || user?.rol === "checkIn" ? (
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={`/home/check-in/${user?.lastEventID || defaultEventID}`}
                    startIcon={<QrCodeScannerTwoTone />}
                    end
                  >
                    Escaner / Buscador
                  </Button>
                </ListItem>
              ) : (
                <></>
              )}
              {user?.rol === "admin" ? (
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={`/home/comunicados/${
                      user?.lastEventID || defaultEventID
                    }`}
                    startIcon={<WhatsApp />}
                    end
                  >
                    Comunicados
                  </Button>
                </ListItem>
              ) : (
                <></>
              )}
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Eventos
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {Object.keys(eventos || {})
                .filter(
                  (id) => eventos[id].estado === true || user?.rol === "admin"
                )
                .map((eventID, i) => (
                  <ListItem key={i} component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={`/home/eventos/${eventID}${
                        user?.rol === "admin" ? "/participantes" : ""
                      }`}
                      startIcon={<CalendarMonthTwoTone />}
                      end
                    >
                      {eventos[eventID].nombre}
                    </Button>
                  </ListItem>
                ))}
            </List>
          </SubMenuWrapper>
        </List>

        {user?.rol === "admin" ? (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Administración
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/home/eventos"
                    startIcon={<CalendarTodayTwoTone />}
                    end
                  >
                    Eventos
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/home/lugares"
                    startIcon={<LocationOnTwoTone />}
                    end
                  >
                    Lugares
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/home/iglesias"
                    startIcon={<ChurchTwoTone />}
                    end
                  >
                    Iglesias
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/home/tiposDeParticipantes"
                    startIcon={<ListAltTwoTone />}
                    end
                  >
                    Tipos de Participantes
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        ) : (
          <></>
        )}

        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Accounts
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/profile/details"
                  startIcon={<AccountCircleTwoToneIcon />}
                >
                  User Profile
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/profile/settings"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Account Settings
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
