import {
  Add,
  AddLocationAlt,
  Delete,
  Diversity3,
  Edit,
  Home,
  Info,
  LocationOn,
  LocationSearching,
  Man,
  Wc,
  Woman,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { push, ref, remove, set } from "firebase/database";
import { useContext, useEffect, useState } from "react";
import CustomLoading from "../../components/CustomLoading";
import { CustomTextField, isValidForm } from "../../components/CustomTextField";
import { database } from "../../config";
import { SiteContext } from "../../contexts/SiteContext";
import { errorMessages, genders, MainContent } from "../../utils";

const defaultModalFormData = { nombre_del_lugar: "", habitaciones: null };
const defaultHabitacionFormData = {
  genero_admitido: "ALL",
  nombre_de_la_habitacion: "",
  capacidad_maxima: "",
};

export const Lugares = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [modalMode, setModalMode] = useState("INSERT");
  const [modalFormData, setModalFormData] = useState(defaultModalFormData);
  const [habitacionesFormData, setHabitacionesFormData] = useState([]);
  const [modalEditedPlace, setModalEditedPlace] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("Error");
  const { user, lugares } = useContext(SiteContext);

  useEffect(() => {
    if (lugares) {
      setLoading(false);
    }
  }, [lugares]);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleHabitacionesFormData = (h, i) =>
    setHabitacionesFormData(
      habitacionesFormData.map((hab, j) => {
        if (j === i) {
          return h;
        } else return hab;
      })
    );

  return (
    <>
      <CustomLoading show={loading} />
      <MainContent header_height={theme.header.height}>
        <div
          style={{
            overflow: "auto",
            maxHeight: "100%",
            width: "100%",
            padding: "15px",
            textAlign: "center",
          }}
        >
          {lugares ? (
            <>
              <Grid
                container
                spacing={2}
                paddingX={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {Object.keys(lugares).map((key, i) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                    <Card>
                      {/* <CardMedia
                        sx={{ height: 140 }}
                        image="/static/images/cards/contemplative-reptile.jpg"
                        title="green iguana"
                    /> */}
                      <CardContent style={{ paddingBottom: "0px" }}>
                        <Typography gutterBottom variant="h5" component="div">
                          {lugares[key].nombre_del_lugar}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          # Habitaciones:
                          {" " +
                            Object.keys(lugares[key].habitaciones ?? {}).length}
                        </Typography>
                      </CardContent>
                      <CardActions style={{ padding: "15px" }}>
                        <Button
                          href={lugares[key].url_ubicacion}
                          target={"_blank"}
                          size="small"
                          color="terciary"
                          variant="outlined"
                          style={{ marginLeft: "auto", marginRight: "10px" }}
                        >
                          Ver Ubicación
                          <LocationOn
                            fontSize="12px"
                            style={{ marginLeft: "2.5px" }}
                          />
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setModalMode("EDIT");
                            setModalEditedPlace(key);
                            setModalFormData(lugares[key]);
                            setHabitacionesFormData(
                              Object.keys(lugares[key]?.habitaciones ?? {}).map(
                                (habKey) => ({
                                  ...lugares[key]?.habitaciones[habKey],
                                  id: habKey,
                                })
                              )
                            );
                            setOpenModal(true);
                          }}
                        >
                          Editar
                          <Edit fontSize="12px" style={{ marginLeft: "5px" }} />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <></>
          )}
          <br />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setModalMode("INSERT");
              setModalEditedPlace();
              setModalFormData(defaultModalFormData);
              setHabitacionesFormData([]);
              setOpenModal(true);
            }}
          >
            Agregar Ubicación
            <AddLocationAlt />
          </Button>
        </div>
      </MainContent>
      <Dialog
        open={openModal}
        PaperProps={{
          elevation: 5,
          style: { width: "90vw", maxWidth: "750px" },
        }}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontFamily: "Bebas Neue",
            letterSpacing: "2.5px",
            paddingBottom: "0px",
          }}
        >
          {modalMode === "INSERT" ? "Nueva" : "Editar"} Ubicación
        </DialogTitle>
        <DialogContent style={{ paddingBottom: "0px" }}>
          <Grid container paddingX={1.5} paddingTop={1} rowGap={3}>
            <Card style={{ width: "inherit" }}>
              <CardHeader
                style={{ textAlign: "center" }}
                title="Datos Generales"
              />
              <Divider />
              <CardContent>
                <Box component="form" autoComplete="off" padding={1.5}>
                  <Grid
                    container
                    columnSpacing={2}
                    style={{ marginBottom: "-20px" }}
                  >
                    <Grid item xs={12} sm={6}>
                      <CustomTextField
                        validateField
                        formData={modalFormData}
                        setFormData={setModalFormData}
                        label="Nombre del Lugar"
                        placeholder="Campamento Manglaralto"
                        suffix={<LocationSearching />}
                        leftHelperText="Nombre Referencial"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextField
                        required={false}
                        formData={modalFormData}
                        setFormData={setModalFormData}
                        label="URL Ubicación"
                        placeholder="https://..."
                        suffix={<LocationOn />}
                        leftHelperText="Link de Google Maps"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
            {habitacionesFormData.map((habita, i) => (
              <Card key={i} style={{ width: "inherit" }}>
                <CardHeader
                  title={`Habitación #${i + 1}`}
                  action={
                    user.cedula === "1312413923" /* Solo High Admin */ ? (
                      <IconButton
                        onClick={() =>
                          setHabitacionesFormData(
                            habitacionesFormData.filter((h, j) => j !== i)
                          )
                        }
                        aria-label="Eliminar"
                      >
                        <Delete />
                      </IconButton>
                    ) : (
                      <></>
                    )
                  }
                  style={{
                    background: theme.palette.terciary.main,
                    paddingTop: "12px",
                    paddingBottom: "12px",
                  }}
                />
                <Divider />
                <CardContent>
                  <Box component="form" autoComplete="off" padding={1.5}>
                    <Grid
                      container
                      columnSpacing={2}
                      style={{ marginBottom: "-20px" }}
                    >
                      <Grid item xs={12} sm={6}>
                        <CustomTextField
                          validateField
                          formData={habita}
                          setFormData={(h) => handleHabitacionesFormData(h, i)}
                          label="Nombre de la Habitación"
                          placeholder={`Cabaña #${i + 1}`}
                          suffix={<Home />}
                          leftHelperText="Nombre Referencial"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTextField
                          select
                          formData={habita}
                          setFormData={(h) => handleHabitacionesFormData(h, i)}
                          label="Género Admitido"
                          prefix={
                            habita["genero_admitido"] === "M" ? (
                              <Man />
                            ) : habita["genero_admitido"] === "F" ? (
                              <Woman />
                            ) : (
                              <Wc />
                            )
                          }
                          leftHelperText="¿Alguna Restricción por Género?"
                        >
                          {[{ value: "ALL", name: "Ambos" }, ...genders].map(
                            (option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.name}
                              </MenuItem>
                            )
                          )}
                        </CustomTextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTextField
                          validateField
                          formData={habita}
                          setFormData={(h) => handleHabitacionesFormData(h, i)}
                          label="Capacidad Máxima"
                          suffix={<Diversity3 />}
                          placeholder="4"
                          leftHelperText="Solo la Cantidad"
                          rightHelperText={`${
                            habita["capacidad_maxima"] ?? 0
                          } Persona/s`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTextField
                          required={false}
                          formData={habita}
                          setFormData={(h) => handleHabitacionesFormData(h, i)}
                          label="Observaciones" // Como para poner si está dañada alguna cama o algo
                          placeholder="Litera de arriba dañada"
                          suffix={<Info />}
                          leftHelperText="Información Adicional"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            ))}
            <Button
              color={"secondary"}
              onClick={() =>
                setHabitacionesFormData([
                  ...habitacionesFormData,
                  defaultHabitacionFormData,
                ])
              }
              variant="contained"
            >
              <Add />
              Agregar Habitación
            </Button>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "20px 24px 20px 24px" }}>
          <Button
            color={"terciary"}
            onClick={() => setOpenModal(false)}
            variant="outlined"
          >
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoadingModal}
            onClick={async () => {
              try {
                setIsLoadingModal(true);

                if (
                  isValidForm(modalFormData) &&
                  habitacionesFormData.every((habitaFormData) =>
                    isValidForm(habitaFormData)
                  )
                ) {
                  if (modalMode === "INSERT") {
                    // Insertamos el nuevo lugar
                    const nuevoLugarRef = await push(
                      ref(database, "lugares"),
                      modalFormData
                    );
                    const promesas = habitacionesFormData.map((hab) => {
                      return push(
                        ref(
                          database,
                          `lugares/${nuevoLugarRef.key}/habitaciones`
                        ),
                        hab
                      );
                    });
                    await Promise.all(promesas);
                  } else {
                    const nuevosDormitorios = [];
                    const updatedPlace = { ...modalFormData };
                    if (habitacionesFormData.length > 0) {
                      updatedPlace["habitaciones"] = {};
                      for (const hab of habitacionesFormData) {
                        if (hab.id) {
                          updatedPlace["habitaciones"][hab.id] = {
                            ...hab,
                            id: null,
                          };
                        } else {
                          nuevosDormitorios.push(hab);
                        }
                      }
                    } else {
                      updatedPlace["habitaciones"] = null;
                    }
                    const promesas = [
                      set(
                        ref(database, `lugares/${modalEditedPlace}`),
                        updatedPlace
                      ),
                      ...nuevosDormitorios.map((hab) => {
                        return push(
                          ref(
                            database,
                            `lugares/${modalEditedPlace}/habitaciones`
                          ),
                          hab
                        );
                      }),
                    ];
                    await Promise.all(promesas);
                  }

                  //await Promise.all(promesas);
                  //Todo bien
                  setSnackbarMessage("Proceso Exitoso!");
                  setSnackbarType("success");
                  setModalFormData(defaultModalFormData);
                  setHabitacionesFormData([]);
                  setModalEditedPlace();
                  setOpenModal(false);
                } else {
                  throw { message: "Complete los campos correctamente" };
                }
              } catch (error) {
                console.log("codigo error", error.code);
                setSnackbarMessage(errorMessages[error.code] ?? error.message);
                setSnackbarType("error");
              } finally {
                setIsLoadingModal(false);
                setOpenSnackbar(true);
              }
            }}
            variant="contained"
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Backdrop open={openSnackbar} style={{ zIndex: "10000" }}>
        <Snackbar
          open={openSnackbar}
          ClickAwayListenerProps={{ onClickAway: handleCloseSnackBar }}
          autoHideDuration={snackbarMessage.length <= 50 ? 1750 : 5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleCloseSnackBar}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity={snackbarType}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Backdrop>
    </>
  );
};
