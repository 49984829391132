import { ArrowBack, Check } from "@mui/icons-material";
import {
  Box,
  Card,
  Typography,
  Container,
  useTheme,
  Grid,
  Divider,
  Backdrop,
  Snackbar,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  errorMessages,
  MainContentScreen as MainContent,
  sleep,
} from "../utils";
import * as SVGInject from "@iconfu/svg-inject";
import styled from "styled-components";
import { LoadingButton } from "@mui/lab";
import {
  confirmPasswordReset,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from "firebase/auth";
import { auth, database } from "../config";
import { CustomTextField, isValidForm } from "../components/CustomTextField";
import { ref, set } from "firebase/database";

const modes = {
  resetPassword: {
    title: "Restablecer Contraseña",
    subtitle: "Por favor, ingresa una contraseña nueva.",
    buttonText: "Cambiar Contraseña",
    validationProcess: (codigo) => {
      return verifyPasswordResetCode(auth, codigo);
    },
    continueButton: (codigo, newPass) => {
      return confirmPasswordReset(auth, codigo, newPass);
    },
  },
};

const StyledSVG = styled(Box)(
  ({ theme }) => `
    .primary-color{ 
        fill: ${theme.palette.primary.main}; 
    }
    .primary-dark-color{ 
        fill: ${theme.palette.primary.dark}; 
    }
    .secondary-color{
        fill: ${theme.palette.secondary.main};
    }
    .secondary-dark-color{
        fill: ${theme.palette.secondary.dark};
    }
    .skin-color{
        fill: #E4BCB0;
    }
    .dark-color{
        fill: #383838;
    }`
);

export const FirebaseActions = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const [mode, setMode] = useState(queryParameters.get("mode"));
  const actionCode = queryParameters.get("oobCode");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("Error");
  const theme = useTheme();
  const navigate = useNavigate();

  const init = async () => {
    setLoading(true);
    SVGInject(document.querySelectorAll("img.injectable"));
    if (modes[mode]) {
      try {
        const email = await modes[mode]
          ?.validationProcess(actionCode)
          .catch(() => {
            setMode("404");
          });
        setFormData({ correo: email, nueva_contrasena: "" });
      } catch (error) {
        setMode("404");
      } finally {
        setLoading(false);
      }
    }
  };

  const changePassword = async () => {
    setLoading(true);
    if (modes[mode]) {
      // Si existe la accion entonces validamos la contraseña ingresada
      try {
        isValidForm(formData);
        await modes[mode]
          ?.continueButton(actionCode, formData["nueva_contrasena"])
          .catch((error) => {
            throw error;
          });
        setSnackbarMessage("Contraseña Actualizada!");
        setSnackbarType("success");
        setOpenSnackbar(true);
        sleep(500).then(() => setSnackbarMessage("Iniciando Sesión..."));
        const userCred = await signInWithEmailAndPassword(
          auth,
          formData["correo"],
          formData["nueva_contrasena"]
        );
        await set(
          ref(database, `users/${userCred.user.uid}/hasUpdatedPass`),
          true
        );
        navigate("/login", { replace: true });
        return;
      } catch (error) {
        console.log(error);
        setSnackbarMessage(errorMessages[error.code] ?? error.message);
        setSnackbarType("error");
        setOpenSnackbar(true);
      }
    } else {
      navigate("/login", { replace: true });
      return; //Para que no intente setear el loading
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
    /* eslint-disable-next-line */
  }, []);

  return (
    <>
      <Helmet>
        <title>{modes[mode]?.title || "404"}</title>
      </Helmet>
      <div
        style={{
          overflow: "auto",
          maxHeight: "100%",
        }}
      >
        <MainContent>
          <Container maxWidth="md">
            <StyledSVG textAlign="center" theme={theme}>
              <img
                alt={modes[mode]?.title || "404"}
                style={{
                  width: "80%",
                  maxWidth: "350px",
                }}
                src={`/static/images/status/${modes[mode]?.title || "404"}.svg`}
                className="injectable"
              />
            </StyledSVG>
            <Container maxWidth="sm">
              <Card sx={{ textAlign: "center", mt: 3, p: 4 }} elevation={14}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  {modes[mode]?.title || "No hay nada aquí"}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 2 }}
                >
                  {modes[mode]?.subtitle || ""}
                </Typography>
                <Divider />
                <Grid container columnSpacing={2}>
                  <Grid item xs={12}>
                    <div style={{ margin: "15px 0px", fontSize: "17px" }}>
                      <b>{formData["correo"]}</b>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      required
                      validateField
                      formData={formData}
                      type="password"
                      autocomplete="password"
                      setFormData={setFormData}
                      placeholder="*********"
                      label="Nueva Contraseña"
                      leftHelperText="Usa minúsculas, mayúsculas, y números"
                      rightHelperText={
                        formData["nueva_contrasena"]?.length >= 6
                          ? /[A-Z]/.test(formData["nueva_contrasena"] || "")
                            ? /.*\d.*$/.test(formData["nueva_contrasena"] || "")
                              ? /[a-z]/.test(formData["nueva_contrasena"] || "")
                                ? undefined
                                : "0 / 1 Minúscula"
                              : "0 / 1 Número"
                            : "0 / 1 Mayúscula"
                          : `${
                              formData["nueva_contrasena"]?.length || 0
                            } / 6 caracteres`
                      }
                      onEnterCallback={changePassword}
                    />
                  </Grid>
                </Grid>
                <LoadingButton
                  loading={loading}
                  variant="outlined"
                  onClick={changePassword}
                >
                  {modes[mode] ? <Check /> : <ArrowBack />}
                  <div style={{ width: "5px" }} />
                  {modes[mode]?.buttonText || "Atras"}
                </LoadingButton>
              </Card>
            </Container>
          </Container>
        </MainContent>
      </div>
      <Backdrop open={openSnackbar} style={{ zIndex: "10000" }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1250}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarType}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Backdrop>
    </>
  );
};
