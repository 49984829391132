import { Box, alpha, lighten, useTheme, capitalize } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import Sidebar from "./Sidebar";
import Header from "./Header";
import { Helmet } from "react-helmet-async";

const SidebarLayout = () => {
  const theme = useTheme();
  const { pathname } = useLocation();

  return (
    <>
      <Helmet>
        <title>
          Home
          {pathname.split("/").length > 2
            ? " | " +
              capitalize(
                pathname.substring(
                  6,
                  pathname.indexOf(pathname.split("/")[2]) +
                    pathname.split("/")[2].length
                )
              )
            : ""}
        </title>
      </Helmet>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          ".MuiPageTitle-wrapper": {
            background:
              theme.palette.mode === "dark"
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === "dark"
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`,
          },
        }}
      >
        <Header />
        <Sidebar />

        <Outlet />
      </Box>
    </>
  );
};

export default SidebarLayout;
