import {
  Alert,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Snackbar,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { onValue, ref, remove, set } from "firebase/database";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import {
  database,
  resendConfirmation,
  sendCustomResetPassLink,
} from "../../config";
import {
  currencyFormatter,
  dateDiff,
  filterDropdown,
  getWordGender,
  MainContent,
  gridButton,
  closeSnackbar,
} from "../../utils";
import {
  DataGrid,
  esES,
  GridActionsCellItem,
  GridFooterContainer,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Check, Close, Lock, Mail } from "@mui/icons-material";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import CustomLoading from "../../components/CustomLoading";
import { SiteContext } from "../../contexts/SiteContext";
import {
  addYears,
  differenceInYears,
  format,
  formatDistanceToNowStrict,
} from "date-fns";
import { LoadingButton } from "@mui/lab";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

export const EventoParticipantes = () => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);
  const {
    user,
    users,
    eventos,
    iglesias,
    usersCedulas,
    tiposDeAsistentes,
    eventData,
    setEventData,
    lugares = {},
    setEventParticipantsGetter,
  } = useContext(SiteContext);
  const { eventID } = useParams();
  const [participants, setParticipants] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [totalAbonos, setTotalAbonos] = useState(0);
  const [scannedUID, setScannedUID] = useState();
  const [obtuveBusqueda, setObtuveBusqueda] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalConfQuestion, setModalConfQuestion] = useState("");
  const [modalConfAction, setModalConfAction] = useState(() => () => {});

  const gridButtonCall = (params) => {
    return gridButton(params, tiposDeAsistentes);
  };

  const handleCloseSnackbar = (evt, reason) => {
    closeSnackbar(evt, reason, setSnackbar);
  };

  const exportData = async () => {
    try {
      setLoadingExport(true);
      const tempCols = [
        ...columns
          .filter((c) => !["uid", "nextBday"].includes(c.field))
          .map((c) => ({
            ...c,
            width: c.excelWidth,
            key: c.field,
            header: c.headerName,
            style: {
              alignment: { vertical: "middle", horizontal: "center" },
              wrapText: true,
              ...c.style,
            },
          })),
      ];
      const workbook = new Workbook();
      workbook.creator = "Diosito";
      workbook.lastModifiedBy = user.name;
      workbook.created = new Date();
      workbook.modified = new Date();
      workbook.lastPrinted = new Date();
      // Force workbook calculation on load
      workbook.calcProperties.fullCalcOnLoad = true;
      // create a sheet
      const worksheet = workbook.addWorksheet("Listado", {
        properties: {
          tabColor: { argb: theme.palette.primary.main.substring(1) },
        },
        views: [{ state: "frozen", ySplit: 4, zoomScale: 90 }],
      });
      const header = worksheet.getCell("A2");
      header.value = "Listado de Participantes " + eventData.nombre;
      header.font = { bold: true, size: 20, color: { argb: "FFFFFFFF" } };
      header.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: theme.palette.primary.main.substring(1) },
      };
      header.alignment = { horizontal: "center" };
      // merge a range of cells
      worksheet.mergeCells(2, 1, 2, tempCols.length);

      // add a table to a sheet
      worksheet.addTable({
        name: "ListadoDeParticipantes",
        ref: "A4",
        headerRow: true,
        totalsRow: true,
        style: {
          theme: "TableStyleMedium1", //Ver "Dar formato como tabla" en Excel
          showRowStripes: true,
        },
        columns: tempCols.map((c) => ({
          name: c.headerName,
          totalsRowFunction: c.totalsRowFunction,
          filterButton: true,
        })),
        rows: participants
          .sort((a, b) =>
            a.fecha_de_inscripcion > b.fecha_de_inscripcion ? 1 : -1
          )
          .map((row) => {
            const p = { ...row };
            p.iglesia =
              iglesias[p.iglesia] +
              (p.iglesia == 7 ? ` (${p.nombre_de_la_iglesia})` : "");
            p.tipo = tiposDeAsistentes[p.tipo];
            p.alergias = p.alergias ? `Sí, ${p.detalle_de_alergia}` : "No";
            p.patologias = p.patologias
              ? `Sí, ${p.detalle_de_patologia}`
              : "No";
            p.celular_emergencias =
              p.contacto_emergencias + " - " + p.celular_emergencias;
            delete p.uid;
            delete p.nextBday;
            delete p.abonos;
            delete p.asistencias;
            delete p.consejerias;
            delete p.musicos;
            delete p.eventos;
            delete p.fecha_creacion;
            delete p.pagos;
            delete p.observaciones;
            delete p.rol;
            delete p.ultimaEdicion;
            delete p.detalle_de_alergia;
            delete p.detalle_de_patologia;
            delete p.nombre_de_la_iglesia;
            delete p.contacto_emergencias;
            delete p.fechas_de_inscripcion;
            return tempCols.map((c) => p[c.field]);
          }),
      });
      tempCols.forEach((col, i) => {
        worksheet.getRow(4).getCell(i + 1).style = {
          alignment: { vertical: "middle", horizontal: "center" }, // Para que siempre estén centradas
        };
      });
      worksheet.columns = tempCols.map((c) => ({ width: c.width }));
      worksheet.getRows(5, participants.length + 1).forEach((row) => {
        tempCols.forEach((col, i) => {
          row.getCell(i + 1).style = col.style;
        });
      });

      tempCols.forEach((col, i) => {
        if (col.type === "boolean") {
          let yala = false;
          worksheet.getColumn(i + 1).eachCell((cell, j) => {
            if (!yala && j > 4) {
              yala = true;
              const colRef =
                cell.address +
                ":" +
                cell.address.replace(/[0-9]/g, "") +
                (participants.length + 4);
              worksheet.addConditionalFormatting({
                ref: colRef,
                rules: [
                  {
                    type: "cellIs",
                    operator: "equal",
                    formulae: ["TRUE"],
                    style: {
                      font: {
                        bold: true,
                        color: { argb: "FFFFFFFF" },
                      },
                      fill: {
                        type: "pattern",
                        pattern: "solid",
                        bgColor: {
                          argb: theme.palette.success.main.substring(1),
                        },
                      },
                    },
                  },
                ],
              });
              worksheet.addConditionalFormatting({
                ref: colRef,
                rules: [
                  {
                    type: "cellIs",
                    operator: "equal",
                    formulae: ["FALSE"],
                    style: {
                      font: {
                        bold: true,
                        color: { argb: "FFFFFFFF" },
                      },
                      fill: {
                        type: "pattern",
                        pattern: "solid",
                        bgColor: {
                          argb: theme.palette.error.main.substring(1),
                        },
                      },
                    },
                  },
                ],
              });
            }
          });
        } else if (col.field === "genero") {
          let yala = false;
          worksheet.getColumn(i + 1).eachCell((cell, j) => {
            if (!yala && j > 4) {
              yala = true;
              const colRef =
                cell.address +
                ":" +
                cell.address.replace(/[0-9]/g, "") +
                (participants.length + 4);
              worksheet.addConditionalFormatting({
                ref: colRef,
                rules: [
                  {
                    type: "cellIs",
                    operator: "equal",
                    formulae: ['"M"'],
                    style: {
                      font: {
                        bold: true,
                        color: { argb: "FFFFFFFF" },
                      },
                      fill: {
                        type: "pattern",
                        pattern: "solid",
                        bgColor: {
                          argb: theme.palette.info.main.substring(1),
                        },
                      },
                    },
                  },
                ],
              });
              worksheet.addConditionalFormatting({
                ref: colRef,
                rules: [
                  {
                    type: "cellIs",
                    operator: "equal",
                    formulae: ['"F"'],
                    style: {
                      font: {
                        bold: true,
                        color: { argb: "FFFFFFFF" },
                      },
                      fill: {
                        type: "pattern",
                        pattern: "solid",
                        bgColor: {
                          argb: theme.palette.error.main.substring(1),
                        },
                      },
                    },
                  },
                ],
              });
            }
          });
        }
      });

      const excelBuffer = await workbook.xlsx.writeBuffer(
        eventData.nombre + ".xlsx"
      );
      var excelBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(excelBlob, `${eventData.nombre}.xlsx`);
    } catch (error) {
      handleProcessRowUpdateError(error);
    } finally {
      setLoadingExport(false);
    }
  };

  const removeScannedQR = async () => {
    setLoading(true);
    await set(ref(database, `busquedas/${user.uid}/${eventID}`), null);
    setScannedUID();
    setLoading(false);
  };

  let subs;
  useEffect(() => {
    if (!subs && !scannedUID && user && !obtuveBusqueda) {
      setObtuveBusqueda(true);
      console.log("* [agrego listener de busquedas] *");
      const busquedasRef = ref(database, `busquedas/${user.uid}/${eventID}`);
      subs = onValue(busquedasRef, (snapshot) => {
        console.log(`[Pedí UID escaneado]`);
        setScannedUID(snapshot.val());
      });
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    if (
      eventData &&
      iglesias &&
      tiposDeAsistentes &&
      users &&
      Object.keys(eventData?.participantes || {}).every((uid) => {
        const userList = Object.keys(users);
        return userList.includes(uid);
      })
    ) {
      const uids = Object.keys(eventData?.participantes || {});
      const listado = [];
      //Buscamos los datos de cada usuario
      for (const uid of uids) {
        const curr = users[uid];
        const fnac = new Date(
          (curr?.fecha_de_nacimiento?.substring(0, 10) || "2000-01-01") +
            "T00:00:00.000"
        );
        const userData = {
          ...curr,
          pagado: curr?.pagos ? curr.pagos[eventID] || false : false,
          observacion: curr?.observaciones
            ? curr.observaciones[eventID] || ""
            : "",
          asistencia: curr?.asistencias
            ? curr.asistencias[eventID] || false
            : false,
          dormitorio:
            curr?.habitaciones &&
            curr.habitaciones[eventID] &&
            lugares[eventData.lugar] &&
            lugares[eventData.lugar]["habitaciones"] &&
            lugares[eventData.lugar]["habitaciones"][curr.habitaciones[eventID]]
              ? lugares[eventData.lugar]["habitaciones"][
                  curr.habitaciones[eventID]
                ].nombre_de_la_habitacion
              : undefined,
          consejeria: curr?.consejerias
            ? curr.consejerias[eventID] || false
            : false,
          musico: curr?.musicos ? curr.musicos[eventID] || false : false,
          abono: curr?.abonos ? curr.abonos[eventID] || 0 : 0,
          fecha_de_inscripcion:
            curr?.fechas_de_inscripcion && curr.fechas_de_inscripcion[eventID]
              ? new Date(curr.fechas_de_inscripcion[eventID])
              : undefined,
          tipo: curr?.eventos ? curr.eventos[eventID] : "0",
          fecha_de_nacimiento: fnac,
          edad: dateDiff(fnac),
          nextBday: addYears(fnac, differenceInYears(new Date(), fnac) + 1),
        };
        listado.push(userData);
      }
      setParticipants(listado);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [eventData, users, iglesias, tiposDeAsistentes]);

  useEffect(() => {
    if (eventos) {
      setEventData(eventos[eventID]);
      setEventParticipantsGetter(eventID);
    }
  }, [eventos, eventID]);

  const processRowUpdate = async (newRow) => {
    const data = users[newRow.uid] || {};
    //Validamos si ya existe el correo nuevo (en caso de haberlo cambiado)
    if (
      Object.keys(usersCedulas)
        .filter((c) => c !== data.cedula)
        .some((c) => usersCedulas[c] === newRow.correo)
    ) {
      // eslint-disable-next-line
      throw {
        message: `No se puede cambiar el correo a ${newRow.correo} porque ya existe`,
      };
    }
    const newData = {
      ...data,
      correo: newRow.correo,
      nombres: newRow.nombres,
      apellidos: newRow.apellidos,
      genero: newRow.genero,
      direccion: newRow.direccion,
      //tipo_de_sangre: newRow.tipo_de_sangre,
      //alergias: newRow.alergias,
      //patologias: newRow.patologias,
      celular: newRow.celular,
      tiktok: newRow?.tiktok || "",
      instagram: newRow?.instagram || "",
      iglesia: newRow.iglesia,
      observaciones: {
        ...(data?.observaciones || {}),
        [eventID]: newRow.observacion,
      },
      pagos: {
        ...(data?.pagos || {}),
        [eventID]: newRow.pagado,
      },
      abonos: {
        ...(data?.abonos || {}),
        [eventID]: newRow.abono,
      },
      asistencias: {
        ...(data?.asistencias || {}),
        [eventID]: newRow.asistencia,
      },
      consejerias: {
        ...(data?.consejerias || {}),
        [eventID]: newRow.consejeria,
      },
      musicos: {
        ...(data?.musicos || {}),
        [eventID]: newRow.musico,
      },
      eventos: {
        ...(data?.eventos || {}),
        [eventID]: newRow.tipo,
      },
    };
    if (JSON.stringify(newData) === JSON.stringify(data)) {
      setSnackbar({
        children: "No se actualizó ningun dato",
        severity: "warning",
      });
      return newRow;
    } else {
      //console.log(data,newData);
    }
    // Actualizamos la Data
    await set(ref(database, `users/${newRow.uid}`), {
      ...newData,
      ultimaEdicion: user.uid,
    });
    setSnackbar({
      children: "Fila Actualizada Correctamente",
      severity: "success",
    });
    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    setSnackbar({ children: error.message, severity: "error" });
  };

  const columns = [
    {
      headerName: "ID del Usuario",
      field: "uid",
      align: "center",
      type: "string",
      editable: false,
      minWidth: 100,
      flex: 1,
      hide: true,
    },
    {
      headerName: "Asistencia",
      field: "asistencia",
      hide: true,
      align: "center",
      minWidth: 90,
      excelWidth: 20,
      type: "boolean",
      valueFormatter: (params) => {
        return params.value ? "Sí" : "No";
      },
      renderCell: (params) =>
        params.value ? <Check color="success" /> : <Close color="error" />,
      editable: true,
      flex: 1,
    },
    {
      headerName: "Consejería",
      field: "consejeria",
      hide: true,
      align: "center",
      minWidth: 90,
      excelWidth: 20,
      type: "boolean",
      valueFormatter: (params) => {
        return params.value ? "Sí" : "No";
      },
      renderCell: (params) =>
        params.value ? <Check color="success" /> : <Close color="error" />,
      editable: true,
      flex: 1,
    },
    {
      headerName: "Músico",
      field: "musico",
      hide: true,
      align: "center",
      minWidth: 80,
      excelWidth: 20,
      type: "boolean",
      valueFormatter: (params) => {
        return params.value ? "Sí" : "No";
      },
      renderCell: (params) =>
        params.value ? <Check color="success" /> : <Close color="error" />,
      editable: true,
      flex: 1,
    },
    {
      headerName: "Tipo",
      field: "tipo",
      align: "center",
      minWidth: 80,
      excelWidth: 20,
      type: "singleSelect",
      valueOptions: Object.keys(tiposDeAsistentes || {}).map((k) => {
        return {
          value: k.toString(),
          label: tiposDeAsistentes[k],
        };
      }),
      valueFormatter: (params) => {
        return (tiposDeAsistentes || {})[params.value];
      },
      renderCell: gridButtonCall,
      filterOperators: filterDropdown,
      editable: true,
      flex: 1,
    },
    {
      headerName: "Cédula",
      field: "cedula",
      align: "center",
      type: "string",
      editable: false,
      minWidth: 110,
      excelWidth: 15,
      flex: 1,
    },
    {
      headerName: "Pagado",
      field: "pagado",
      align: "center",
      minWidth: 100,
      excelWidth: 20,
      type: "boolean",
      valueFormatter: (params) => {
        return params.value ? "Sí" : "No";
      },
      renderCell: (params) =>
        params.value ? <Check color="success" /> : <Close color="error" />,
      editable: true,
      flex: 1,
    },
    {
      headerName: "Abono",
      field: "abono",
      align: "center",
      minWidth: 100,
      excelWidth: 15,
      totalsRowFunction: "sum",
      style: {
        numFmt: '_ $* #,##0.00 _ ;[Red] $* (#,##0.00)_ ;_ $* " - "?? _ ;_ @_ ',
      },
      type: "number",
      valueFormatter: ({ value }) =>
        currencyFormatter.format(value).replace("$", "$ "),
      editable: true,
      flex: 1,
    },
    {
      headerName: "Observación",
      field: "observacion",
      valueFormatter: ({ value }) => (value && value !== "" ? value : "-"),
      renderCell: ({ value }) => (
        <Tooltip
          arrow
          placement="right"
          title={value === "-" || value.length <= 19 ? "" : value}
        >
          <span>
            {value.substring(0, 19) + (value.length > 19 ? "..." : "")}
          </span>
        </Tooltip>
      ),
      align: "center",
      minWidth: 150,
      excelWidth: 31.5,
      editable: true,
      flex: 3,
    },
    {
      headerName: "Nombres",
      field: "nombres",
      align: "right",
      minWidth: 160,
      excelWidth: 19,
      style: { alignment: { vertical: "middle", horizontal: "right" } },
      editable: true,
      flex: 2,
    },
    {
      headerName: "Apellidos",
      field: "apellidos",
      minWidth: 160,
      excelWidth: 19,
      style: { alignment: { vertical: "middle", horizontal: "left" } },
      editable: true,
      flex: 2,
    },
    {
      headerName: "Género",
      field: "genero",
      align: "center",
      minWidth: 75,
      excelWidth: 13,
      editable: true,
      renderCell: gridButtonCall,
      flex: 1,
    },
    {
      headerName: "F. Nacimiento",
      field: "fecha_de_nacimiento",
      align: "center",
      type: "date",
      minWidth: 125,
      excelWidth: 17,
      style: { numFmt: "dd/mm/yyyy" },
      valueFormatter: (params) => {
        if (params.value == null) {
          return "Sin registro";
        }
        const fnac = params.value;
        return format(fnac, "dd/MM/yyyy");
      },
      renderCell: (params) =>
        params.value ? (
          <Tooltip
            arrow
            placement="right"
            title={
              "🥳 B-Day dentro de " +
              formatDistanceToNowStrict(params.row.nextBday)
            }
          >
            <span>{format(params.value, "d 'de' MMM',' yyyy")}</span>
          </Tooltip>
        ) : (
          <span>-</span>
        ),
      editable: false,
      flex: 2,
      hide: true,
    },
    {
      headerName: "Next B-Day",
      field: "nextBday",
      align: "center",
      type: "date",
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "Sin registro";
        }
        return "Dentro de " + formatDistanceToNowStrict(params.value);
      },
      renderCell: (params) =>
        params.value ? (
          <Tooltip
            arrow
            placement="right"
            title={format(params.row.fecha_de_nacimiento, "d 'de' MMM',' yyyy")}
          >
            <span>
              {"Dentro de " + formatDistanceToNowStrict(params.value)}
            </span>
          </Tooltip>
        ) : (
          <span>-</span>
        ),
      editable: false,
      flex: 2,
      hide: true,
    },
    {
      headerName: "Edad",
      field: "edad",
      align: "center",
      type: "number",
      minWidth: 100,
      excelWidth: 12,
      valueFormatter: ({ value: edad }) => `${edad} años`,
      editable: false,
      flex: 1,
    },
    {
      headerName: "Dirección",
      field: "direccion",
      align: "center",
      minWidth: 150,
      excelWidth: 25,
      style: { alignment: { vertical: "middle", horizontal: "center" } },
      editable: true,
      flex: 1,
      valueFormatter: ({ value }) => (value?.length > 0 ? value : "-"),
      renderCell: ({ value }) => (
        <Tooltip
          arrow
          placement="left"
          title={value?.length <= 19 ? "" : value}
        >
          <span>
            {value?.substring(0, 19) + (value?.length > 19 ? "..." : "")}
          </span>
        </Tooltip>
      ),
    },
    {
      headerName: "Celular",
      field: "celular",
      align: "center",
      type: "string",
      renderCell: gridButtonCall,
      minWidth: 75,
      excelWidth: 15,
      editable: true,
      flex: 1,
    },
    {
      headerName: "Correo",
      field: "correo",
      align: "center",
      type: "string",
      minWidth: 75,
      excelWidth: 33.5,
      renderCell: gridButtonCall,
      editable: true,
      flex: 1,
      hide: true,
    },
    {
      headerName: "TikTok",
      field: "tiktok",
      align: "center",
      minWidth: 75,
      excelWidth: 20,
      hide: true,
      renderCell: gridButtonCall,
      editable: true,
      flex: 1,
    },
    {
      headerName: "Instagr.",
      field: "instagram",
      align: "center",
      minWidth: 75,
      excelWidth: 18,
      hide: true,
      renderCell: gridButtonCall,
      editable: true,
      flex: 1,
    },
    {
      headerName: "Sangre",
      field: "tipo_de_sangre",
      align: "center",
      minWidth: 50,
      excelWidth: 12,
      flex: 1,
      hide: true,
    },
    {
      headerName: "Alérgias",
      field: "alergias",
      align: "center",
      minWidth: 85,
      excelWidth: 25,
      type: "boolean",
      valueFormatter: (params) => {
        return params.value ? "Sí" : "No";
      },
      renderCell: (params) =>
        params.value ? (
          <Tooltip
            arrow
            placement="right"
            title={params.row.detalle_de_alergia || "No definido"}
          >
            <Check color="success" />
          </Tooltip>
        ) : (
          <Close color="error" />
        ),
      flex: 1,
    },
    {
      headerName: "Patologías",
      field: "patologias",
      align: "center",
      minWidth: 90,
      excelWidth: 17.5,
      type: "boolean",
      valueFormatter: (params) => {
        return params.value ? "Sí" : "No";
      },
      renderCell: (params) =>
        params.value ? (
          <Tooltip
            arrow
            placement="right"
            title={params.row.detalle_de_patologia || "No definido"}
          >
            <Check color="success" />
          </Tooltip>
        ) : (
          <Close color="error" />
        ),
      flex: 1,
      hide: true,
    },
    {
      headerName: "Emergencias",
      field: "celular_emergencias",
      align: "center",
      minWidth: 75,
      excelWidth: 38,
      renderCell: gridButtonCall,
      flex: 1,
      hide: true,
    },
    {
      headerName: "Medicamentos",
      field: "medicamentos",
      align: "center",
      minWidth: 70,
      excelWidth: 18,
      valueFormatter: ({ value }) => (value !== "" ? value : "-"),
      renderCell: (params) =>
        params.value && params.value !== "" ? (
          <Tooltip arrow title={params.value} placement="right">
            <span>{params.value}</span>
          </Tooltip>
        ) : (
          <span>-</span>
        ),
      flex: 1,
      hide: true,
    },
    {
      headerName: "Iglesia",
      field: "iglesia",
      align: "center",
      type: "singleSelect",
      valueOptions: Object.keys(iglesias || {}).map((k) => {
        return { value: k.toString(), label: iglesias[k] };
      }),
      valueFormatter: (params) => {
        return (
          (iglesias || {})[params.value] +
          (params.value === "7"
            ? ` (${
                participants.find((p) => p.uid === params.id)[
                  "nombre_de_la_iglesia"
                ]
              })`
            : "")
        );
      },
      renderCell: (params) => {
        const opcion = (iglesias || {})[params.value];
        const otra = participants.find((p) => p.uid === params.id)[
          "nombre_de_la_iglesia"
        ];
        const txt = opcion + (params.value === "7" ? ` (${otra})` : "");
        const max = 25;
        const celda = (params.value === "7" ? otra : opcion) || "";
        return (
          <Tooltip
            arrow
            title={celda.length > max ? txt : ""}
            placement={"left"}
          >
            <span>
              {celda.substring(0, max) + (celda.length > max ? "..." : "")}
            </span>
          </Tooltip>
        );
      },
      filterOperators: filterDropdown,
      minWidth: 175,
      excelWidth: 30,
      editable: true,
      flex: 3,
    },
    {
      headerName: "F. Inscripción",
      field: "fecha_de_inscripcion",
      align: "center",
      type: "date",
      minWidth: 130,
      excelWidth: 17,
      style: { numFmt: "dd/mm/yyyy" },
      valueFormatter: ({ value: finscrip }) => {
        if (finscrip == null) {
          return "-";
        }
        return finscrip;
      },
      renderCell: ({ value: finscrip }) => {
        if (!finscrip) {
          return "-";
        }
        return (
          <Tooltip
            arrow
            title={format(finscrip, "dd 'de' MMM 'a las' HH:mm")}
            placement={"left"}
          >
            <span>
              {formatDistanceToNowStrict(finscrip, { addSuffix: true })}
            </span>
          </Tooltip>
        );
      },
      editable: false,
      flex: 1,
    },
    {
      headerName: "Dormitorio",
      field: "dormitorio",
      align: "center",
      minWidth: 125,
      excelWidth: 15.5,
      valueFormatter: ({ value }) => {
        return value ? value : "-";
      },
      editable: false,
      flex: 1,
      hide: true,
    },
    {
      headerName: "Login",
      field: "hasUpdatedPass",
      align: "center",
      minWidth: 100,
      excelWidth: 20,
      type: "boolean",
      valueFormatter: (params) => {
        return params.value ? "Sí" : "No";
      },
      renderCell: (params) =>
        params.value ? (
          <Tooltip
            arrow
            placement="right"
            title={
              params.value
                ? "Ha cambiado su contraseña"
                : "No ha cambiado su contraseña"
            }
          >
            <Check color="success" />
          </Tooltip>
        ) : (
          <Close color="error" />
        ),
      editable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 120,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <Tooltip
            PopperProps={{ disablePortal: true }}
            title={"Re-Confirmar Registro"}
            placement={"left"}
            arrow
          >
            <GridActionsCellItem
              icon={
                participants.find((p) => p.uid === id)[
                  "isLoadingConfirmationMail"
                ] === true ? (
                  <CircularProgress thickness={7.5} size={15} />
                ) : (
                  <Mail />
                )
              }
              label="Confirmación de Registro"
              className="textPrimary"
              onClick={async () => {
                const doIt = async () => {
                  let u = participants.find((p) => p.uid === id);
                  u["isLoadingConfirmationMail"] = true;
                  try {
                    await resendConfirmation({ userUID: id, eventID: eventID });
                    setSnackbar({
                      children: `Listo! Se envió el correo a ${u.correo}`,
                      severity: "success",
                    });
                  } catch (error) {
                    setSnackbar({
                      children: error?.message,
                      severity: "error",
                    });
                  } finally {
                    u["isLoadingConfirmationMail"] = false;
                  }
                };
                let u = participants.find((p) => p.uid === id);
                setOpenModal(true);
                setModalConfQuestion(
                  `¿Segur${getWordGender(
                    user?.genero ?? "M"
                  )} de que deseas enviar otro correo de confirmación de inscripción a [${
                    u.correo
                  }]?`
                );
                setModalConfAction(() => doIt);
              }}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip
            PopperProps={{ disablePortal: true }}
            title={"Restablecer Contraseña"}
            placement={"left"}
            arrow
          >
            <GridActionsCellItem
              icon={
                participants.find((p) => p.uid === id)["isLoadingPassword"] ===
                true ? (
                  <CircularProgress thickness={7.5} size={15} />
                ) : (
                  <Lock />
                )
              }
              label="Recuperación de Contraseña"
              onClick={async () => {
                const doIt = async () => {
                  let u = participants.find((p) => p.uid === id);
                  u["isLoadingPassword"] = true;
                  try {
                    await sendCustomResetPassLink({
                      correo: u.correo,
                      themeName: eventData.themeName,
                      emailSender: eventData?.emailSender,
                    });
                    setSnackbar({
                      children: `Listo! Se envió el correo a ${u.correo}`,
                      severity: "success",
                    });
                  } catch (error) {
                    setSnackbar({
                      children: error?.message,
                      severity: "error",
                    });
                  } finally {
                    u["isLoadingPassword"] = false;
                  }
                };
                let u = participants.find((p) => p.uid === id);
                setOpenModal(true);
                setModalConfQuestion(
                  `¿Segur${getWordGender(
                    user?.genero ?? "M"
                  )} de que deseas enviar un correo de restablecimiento de contraseña a [${
                    u.correo
                  }]?`
                );
                setModalConfAction(() => doIt);
              }}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip
            PopperProps={{ disablePortal: true }}
            title={"Anular Inscripción"}
            placement={"left"}
            arrow
          >
            <GridActionsCellItem
              icon={
                participants.find((p) => p.uid === id)[
                  "isLoadingDeleteInscription"
                ] === true ? (
                  <CircularProgress thickness={7.5} size={15} />
                ) : (
                  <Close />
                )
              }
              label="Anular la Inscripción"
              onClick={async () => {
                const doIt = async () => {
                  let u = participants.find((p) => p.uid === id);
                  u["isLoadingDeleteInscription"] = true;
                  try {
                    await remove(
                      ref(database, `eventos/${eventID}/participantes/${id}`)
                    );
                    setSnackbar({
                      children: `Listo! Se anuló la inscripción de ${
                        u.nombres.split(" ")[0]
                      }`,
                      severity: "success",
                    });
                  } catch (error) {
                    setSnackbar({
                      children: error?.message,
                      severity: "error",
                    });
                  } finally {
                    u["isLoadingDeleteInscription"] = false;
                  }
                };
                let u = participants.find((p) => p.uid === id);
                setOpenModal(true);
                setModalConfQuestion(
                  `¿Segur${getWordGender(
                    user?.genero ?? "M"
                  )} de que deseas anular la inscripción de [${
                    u.nombres + " " + u.apellidos
                  }]? (Sus datos seguiran almacenados en el sistema, pero ya no estará inscrito en este evento)`
                );
                setModalConfAction(() => doIt);
              }}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Inscritos | {eventData?.nombre || ""}</title>
      </Helmet>
      <CustomLoading show={loading} logo={eventData?.logo} />
      <MainContent header_height={theme.header.height}>
        <Container maxWidth="xl">
          <div style={{ position: "absolute", width: "100%", top: 40 }}>
            <Box
              sx={{
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {isSM ? (
                <></>
              ) : (
                <Typography variant="h2" color="secondary">
                  {eventData?.nombre || (
                    <CircularProgress
                      thickness={7.5}
                      size={15}
                      style={{
                        position: "absolute",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    />
                  )}
                </Typography>
              )}
              {scannedUID ? (
                <Chip
                  style={{
                    marginLeft: isSM ? "calc(100% - 275px)" : "50px",
                    zIndex: 10,
                  }}
                  label="Entrada Escaneada"
                  onDelete={removeScannedQR}
                />
              ) : (
                <></>
              )}
            </Box>
          </div>
          <Card
            sx={{ textAlign: "center", pl: 2, pr: 2 }}
            elevation={14}
            style={{
              overflow: "auto",
              height: `calc(100vh - ${theme.header.height} - 45px)`,
            }}
          >
            <DataGrid
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Footer: () => (
                  <GridFooterContainer>
                    <div>
                      {isSM ? "" : "Total Recaudado:"}
                      <b style={{ marginLeft: "10px" }}>
                        {currencyFormatter
                          .format(totalAbonos)
                          .replace("$", "$ ")}
                      </b>
                      {isSM ? (
                        <></>
                      ) : (
                        <FormControlLabel
                          style={{ marginLeft: "50px" }}
                          control={<Switch />}
                          onChange={(event) => {
                            set(
                              ref(
                                database,
                                `eventos/${eventID}/allowEditHabitaciones`
                              ),
                              event.target.checked
                            );
                          }}
                          checked={eventData?.allowEditHabitaciones ?? false}
                          label={
                            "Cambio de Cuartos " +
                            (eventData?.allowEditHabitaciones === true
                              ? "Permitido"
                              : "NO Permitido")
                          }
                        />
                      )}
                    </div>
                    <GridPagination
                      style={{ marginLeft: "auto" }}
                      rowsPerPageOptions={[]}
                    />
                  </GridFooterContainer>
                ),
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <LoadingButton
                      size="small"
                      loading={loadingExport}
                      onClick={exportData}
                    >
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        style={{ marginRight: "10px" }}
                      />
                      Exportar
                    </LoadingButton>
                    <GridToolbarQuickFilter
                      id="quickFilterListado"
                      //debounceMs={0}
                      style={{ marginLeft: "auto" }}
                      variant="outlined"
                      placeholder="Buscar..."
                      size="small"
                      onKeyDown={(event) => {
                        setTimeout(() => {
                          document.getElementById("quickFilterListado").focus();
                        }, 550);
                      }}
                    />
                  </GridToolbarContainer>
                ),
              }}
              density={"compact"}
              headerHeight={60}
              onStateChange={(state) => {
                localStorage.setItem(
                  "SAVED_COLS",
                  JSON.stringify(state.columns.columnVisibilityModel)
                );
                const visibleRows = state.filter.visibleRowsLookup;
                let visibleItems = [];
                for (const [id, value] of Object.entries(visibleRows)) {
                  if (value === true) {
                    visibleItems.push(id);
                  }
                }
                const res = participants.filter((item) =>
                  visibleItems.includes(item.uid)
                );
                const total = res
                  .map((item) => item.abono)
                  .reduce((a, b) => a + b, 0);
                setTotalAbonos(total);
              }}
              sx={{
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: theme.colors.alpha.black[10],
                  // color: "red"
                },
                "& .app-theme--header": {
                  backgroundColor: "primary.main",
                  color: "white",
                },
                "& .app-theme--header .MuiIconButton-root": {
                  color: "white",
                },
                "& .MuiDataGrid-toolbarContainer": {
                  "& > :not(.MuiFormControl-root)": {
                    [theme.breakpoints.down("md")]: {
                      display: "none",
                    },
                  },
                  "& .MuiFormControl-root": {
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  },
                },
              }}
              editMode="row"
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              getRowId={(row) => row.uid}
              columns={columns.map((c) => {
                const prevSavedCols = localStorage.getItem("SAVED_COLS");
                return {
                  ...c,
                  headerAlign: "center",
                  headerClassName: "app-theme--header",
                  hide:
                    prevSavedCols !== null
                      ? !JSON.parse(prevSavedCols)[c.field]
                      : c?.hide || false,
                };
              })}
              initialState={{
                sorting: {
                  sortModel: [{ field: "fecha_de_inscripcion", sort: "asc" }],
                },
              }}
              rows={participants.filter(
                (p) => !scannedUID || p.uid === scannedUID
              )}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Card>
        </Container>
      </MainContent>
      <Dialog
        open={openModal}
        PaperProps={{
          elevation: 5,
          style: { width: "90vw", maxWidth: "500px" },
        }}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontFamily: "Bebas Neue",
            letterSpacing: "2.5px",
            paddingBottom: "0px",
          }}
        >
          Confirmación
        </DialogTitle>
        <DialogContent style={{ paddingBottom: "0px" }}>
          <Grid container paddingY={1.5} paddingTop={1} rowGap={3} columns={12}>
            <Grid item xs={12} textAlign={"center"}>
              {modalConfQuestion}
            </Grid>
            <Grid item xs={6} display={"flex"}>
              <Button
                color={"error"}
                onClick={() => setOpenModal(false)}
                variant="outlined"
                style={{ marginLeft: "auto", marginRight: "10px" }}
              >
                No, cancelar
                <Close style={{ marginLeft: "5px" }} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color={"secondary"}
                onClick={() => {
                  setOpenModal(false);
                  modalConfAction();
                }}
                variant="contained"
              >
                Sí, segur{getWordGender(user?.genero ?? "M")}
                <Check style={{ marginLeft: "5px" }} />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};
