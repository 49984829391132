import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import "./config";
import { EventoParticipantes } from "./screens/Menu/EventoParticipantes";
import { CssBaseline } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ThemeProvider from "./theme/ThemeProvider";
import { HelmetProvider } from "react-helmet-async";
import { SidebarProvider } from "./contexts/SidebarContext";
import { SiteContext, SiteProvider } from "./contexts/SiteContext";
import MessagePage from "./screens/MessagePage";
import Registro from "./screens/Registro";
import Login from "./screens/Login";
import ConfirmationPage from "./screens/ConfirmationPage";
import { Home } from "./screens/Home";
import { Eventos } from "./screens/Menu/Eventos";
import { FirebaseActions } from "./screens/FirebaseActions";
import { Profile } from "./screens/Menu/Profile";
import { MyQR } from "./screens/Menu/MyQR";
import { CheckIn } from "./screens/Menu/CheckIn";
import { Lugares } from "./screens/Menu/Lugares";
import { Dormitorio } from "./screens/Menu/Dormitorio";
import { Comunicados } from "./screens/Menu/Comunicados";
//import FillUserData from "./screens/FillUserData";

const routes = [
  {
    visible: "0",
    path: "/registro/:eventID",
    element: <Registro />,
    errorElement: (
      <MessagePage
        errorCode="Oops"
        errorMessage="Ha ocurrido un error inesperado."
      />
    ),
  },
  {
    visible: "0",
    path: "/confirmacion/:eventID",
    element: <ConfirmationPage />,
    errorElement: (
      <MessagePage
        errorCode="Oops"
        errorMessage="Ha ocurrido un error inesperado."
      />
    ),
  },
  {
    visible: "0",
    path: "/login",
    element: <Login />,
    errorElement: (
      <MessagePage
        errorCode="Oops"
        errorMessage="Ha ocurrido un error inesperado."
        buttonText="Regresar"
        buttonAction={(navigate) => navigate("/login", { replace: true })}
      />
    ),
  },
  {
    visible: "0",
    path: "/",
    element: <Login />,
    errorElement: (
      <MessagePage
        errorCode="Oops"
        errorMessage="Ha ocurrido un error inesperado."
        buttonText="Regresar"
        buttonAction={(navigate) => navigate("/login", { replace: true })}
      />
    ),
  },
  {
    visible: "0",
    path: "/home",
    element: <Home />,
    errorElement: (
      <MessagePage
        errorCode="Oops"
        errorMessage="Ha ocurrido un error inesperado."
        buttonText="Regresar"
        buttonAction={(navigate) =>
          navigate("/home/eventos", { replace: true })
        }
      />
    ),
    children: [
      { visible: "0", path: "eventos", element: <Eventos /> },
      { visible: "0", path: "eventos/:eventID", element: <Eventos /> },
      { visible: "0", path: "perfil", element: <Profile /> },
      { visible: "0", path: "entradas/:eventID", element: <MyQR /> },
      { visible: "0", path: "habitaciones/:eventID", element: <Dormitorio /> },
      { visible: "checkIn", path: "check-in/:eventID", element: <CheckIn /> },
      {
        visible: "admin",
        path: "comunicados/:eventID",
        element: <Comunicados />,
      },
      {
        visible: "admin",
        path: "lugares",
        element: <Lugares />,
      },
      {
        visible: "admin",
        path: "eventos/:eventID/participantes",
        element: <EventoParticipantes />,
      },
      {
        visible: "0",
        path: "*",
        element: (
          <MessagePage
            isInnerPage={true}
            errorCode="Oops"
            errorMessage="Estamos en Construcción"
            errorSubMessage="Este módulo aún no está disponible :("
            buttonText="Volver a Eventos"
            buttonAction={(navigate) => navigate("/home/eventos")}
          />
        ), //Por defecto 404
      },
    ],
  },
  /*
  {
    path: "/dev-fill",
    element: <FillUserData />,
    errorElement: (
      <MessagePage
        errorCode="Oops"
        errorMessage="Ha ocurrido un error inesperado."
      />
    ),
  },*/
  {
    visible: "0",
    path: "/firebaseActions",
    element: <FirebaseActions />,
    errorElement: (
      <MessagePage
        errorCode="Oops"
        errorMessage="Ha ocurrido un error inesperado."
        buttonText="Regresar"
        buttonAction={(navigate) => navigate("/login", { replace: true })}
      />
    ),
  },
  {
    visible: "0",
    path: "*",
    element: <MessagePage />, //Por defecto 404
  },
];

const App = () => {
  const { user } = useContext(SiteContext);
  return (
    <RouterProvider
      router={createBrowserRouter(
        routes
          .filter(
            (r) =>
              !user ||
              r.visible === "0" ||
              user?.rol === "admin" ||
              user?.rol === r.visible
          )
          .map((r) => ({
            ...r,
            children: r.children?.filter(
              (subr) =>
                !user ||
                subr.visible === "0" ||
                user?.rol === "admin" ||
                user?.rol === subr.visible
            ),
          }))
      )}
    />
  );
};

const root = document.getElementById("root");
ReactDOM.render(
  <HelmetProvider>
    <ThemeProvider>
      <SiteProvider>
        <SidebarProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <CssBaseline />
            <App />
          </LocalizationProvider>
        </SidebarProvider>
      </SiteProvider>
    </ThemeProvider>
  </HelmetProvider>,
  root
);
