import { IconButton, InputAdornment, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import { capitalize, dateDiff, normalizar } from "../utils";
import { RemoveRedEye, VisibilityOff } from "@mui/icons-material";
import { defaultVisibleFields } from "./FormularioDatos";

/**
 * @param {string} field
 * @param {FormData Object} formData
 * @param {boolean} throwError To throw or not to throw an exception when not valid
 * @returns
 */
export const isValidField = (
  field,
  formData,
  throwError = false,
  correos = [],
  evento
) => {
  const label = capitalize(field.replaceAll("_", " "));
  const notEmpty = /\S+/; //También valida cuando hay solo espacios
  const complexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
  const onlyLetters = /^[A-zÀ-ú\s]+$/;
  const onlyNumbers = /^\d+$/;
  const lettersAndNumbers = /^[A-Za-z0-9_.-]*$/;
  // eslint-disable-next-line
  const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let response = { isValid: true, message: "" };

  if (
    field === "cedula" ||
    field === "celular" ||
    field === "celular_emergencias"
  ) {
    const validacion =
      (formData[field] ?? "").length === 10 &&
      onlyNumbers.test(formData[field]) &&
      (field === "cedula" || formData[field].substring(0, 2) === "09");
    response.isValid = validacion;
    response.message = `Ingresa solo los 10 digitos de tu ${label}`;
  } else if (field === "nombres") {
    response.isValid =
      notEmpty.test(formData[field]) &&
      onlyLetters.test(formData[field]) &&
      formData[field].split(" ").length >= 1 && //minimo 1 nombre
      formData[field].split(" ").length <= 4;
    response.message =
      "Tus nombres no pueden tener números ni caracteres especiales";
  } else if (field === "apellidos" || field === "contacto_emergencias") {
    response.isValid =
      notEmpty.test(formData[field]) &&
      onlyLetters.test(formData[field]) &&
      formData[field].split(" ").length >= 2 && //minimo 2 apellidos
      formData[field].split(" ").length <= 6;
    response.message = `El campo '${label}' solo puede tener letras`;
  } else if (field === "detalle_de_alergia") {
    response.isValid =
      formData["alergias"] === false || notEmpty.test(formData[field]);
    response.message = `Como tienes alergias, debes especificar un detalle`;
  } else if (field === "detalle_de_patologia") {
    response.isValid =
      formData["patologias"] === false || notEmpty.test(formData[field]);
    response.message = `Como cuentas con una patología, debes especificar un detalle`;
  } else if (field === "nombre_de_la_iglesia") {
    response.isValid =
      formData["iglesia"] !== "7" ||
      (notEmpty.test(formData[field]) &&
        (formData[field]?.split(" ")?.length || 11) <= 10);
    response.message =
      "Escribe el nombre de la iglesia a la que perteneces, sin importar su religión";
  } else if (field === "correo") {
    response.isValid = validEmail.test(formData[field]);
    response.message = `Debes ingresar un correo válido, recuerda que por este medio estaremos en contacto!`;
    if (response.isValid) {
      response.isValid = !correos.includes(formData[field]);
      response.code = "auth/email-already-in-use";
    }
  } else if (field === "contrasena") {
    response.isValid =
      notEmpty.test(formData[field]) && formData[field].length >= 6;
    response.message = `Contraseña no válida`;
  } else if (field === "nueva_contrasena") {
    response.isValid = complexPass.test(formData[field]);
    response.message = `La contraseña debe incluir minúsculas, mayúsculas, y números`;
  } else if (field === "fecha_de_nacimiento") {
    const age = dateDiff(Date.parse(formData[field]));
    response.isValid =
      notEmpty.test(formData[field]) &&
      age >= (evento?.edadMinima ?? 5) &&
      age <= 100;
    response.message = `Tu edad no cumple los requisitos necesarios`;
  } else if (field === "direccion") {
    response.isValid = formData[field]?.length >= 7;
    response.message = `El campo '${label}' debe ser un poco más específico: sector, calle, villa, etc.`;
  } else if (field === "instagram" || field === "tiktok") {
    response.isValid = lettersAndNumbers.test(formData[field]);
    response.message = `El campo '${label}' solo debe contener tu nombre de usuario, sin @`;
  } else if (field === "nombre_del_lugar") {
    response.isValid = notEmpty.test(formData[field]);
    response.message = `Se debe especificar un mombre para el lugar`;
  } else if (field === "nombre_de_la_habitacion") {
    response.isValid = notEmpty.test(formData[field]);
    response.message = `Se debe especificar un mombre para cada habitación`;
  } else if (field === "capacidad_maxima") {
    response.isValid =
      notEmpty.test(formData[field]) &&
      onlyNumbers.test(formData[field]) &&
      parseInt(formData[field]) > 0;
    response.message = `La capacidad máxima de cada habitación debe ser correcta`;
  }
  if (throwError && !response.isValid) {
    // eslint-disable-next-line
    throw response;
  }
  return response.isValid;
};

/**
 * Lanza una excepción cuando NO es válido algún campo (Usar dentro de try-catch)
 * @param {FormData Object} data
 * @returns
 */
export const isValidForm = (
  data,
  correos = [],
  visibleFields = defaultVisibleFields,
  evento
) => {
  const fields = Object.keys(data);
  const validatedFields = fields.map((field) => {
    if (visibleFields.includes(field))
      return isValidField(field, data, true, correos, evento);
    return true;
  });
  //Validamos si todos los campos son válidos
  return validatedFields.length > 0
    ? validatedFields.reduce((prev, curr) => prev && curr)
    : false;
};

const maxText = 500;

export const CustomTextField = ({
  label = "",
  validateField = false,
  importantField = false,
  id,
  type = "text",
  autocomplete = "off",
  disabled = false,
  fullWidth = true,
  required = true,
  placeholder = "",
  formData,
  setFormData,
  maxLength = maxText,
  leftHelperText,
  autoCapitalize = "none",
  rightHelperText,
  inputMode,
  style = {},
  select = false,
  prefix,
  suffix,
  children,
  lowercase = false,
  color = "secondary",
  onBlurCallback = () => {},
  onEnterCallback,
}) => {
  const theme = useTheme();
  const [localType, setLocalType] = useState(type);
  const [focus, setFocus] = useState(false);
  const name = normalizar(label)
    .toLowerCase()
    .replaceAll(" ", "_")
    .replaceAll("/", "");

  return (
    <TextField
      sx={
        importantField && formData[name] !== ""
          ? {
              ".MuiFormHelperText-root:not(.Mui-error)": {
                color: `${theme.palette.warning.dark} !important`,
              },
            }
          : {}
      }
      id={id}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      select={select}
      style={style}
      required={required}
      type={localType}
      label={label}
      placeholder={placeholder}
      onKeyDown={
        onEnterCallback
          ? (event) => {
              var key = event.keyCode || event.key;
              if (key === 13) {
                //Si es la tecla ENTER
                event.preventDefault();
                onEnterCallback();
              }
            }
          : undefined
      }
      onFocus={() => {
        setFocus(true);
      }}
      onBlur={(e) => {
        setFocus(false);
        let nuevaData = { ...formData };
        let text = e.target.value;
        if (autoCapitalize) {
          text = capitalize(text);
        }
        if (lowercase) {
          text = text.toLowerCase();
        }
        if (autoCapitalize !== "none" || lowercase) {
          nuevaData = { ...nuevaData, [name]: text.trim() };
          setFormData(nuevaData);
        }
        onBlurCallback(nuevaData);
      }}
      error={
        !isValidField(name, formData) &&
        ((formData[name] ?? "")?.length !== 0 || validateField)
      }
      InputLabelProps={{
        shrink:
          focus || (formData[name] ?? "") !== "" || autocomplete !== "off",
      }}
      InputProps={{
        autoComplete: autocomplete,
        startAdornment: (
          <InputAdornment position="start" style={{ color: "inherit" }}>
            {prefix === "@" && !focus && (formData[name] ?? "") === ""
              ? ""
              : prefix}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            style={{ color: "inherit" }}
            position="end"
            onClick={() => {
              if (type === "password") {
                setLocalType(localType === "password" ? "text" : "password");
              }
            }}
          >
            {type !== "password" ? (
              suffix
            ) : (
              <IconButton sx={{ marginRight: "-8px" }}>
                {localType === "password" ? (
                  suffix || <RemoveRedEye />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: maxLength,
        autoCapitalize: autoCapitalize,
        inputMode: inputMode,
      }}
      FormHelperTextProps={{
        component: "div",
      }}
      helperText={
        <div style={{ width: "100%", display: "flex" }}>
          {(leftHelperText === undefined && !required) || leftHelperText ? (
            <TurnLeftIcon
              style={{
                fontSize: "15px",
                rotate: "180deg",
                marginRight: "2.5px",
              }}
            />
          ) : (
            <></>
          )}

          <div>
            {leftHelperText === undefined && !required
              ? "Campo Opcional"
              : leftHelperText ?? ""}
          </div>
          <div style={{ marginLeft: "auto" }}>
            {rightHelperText ??
              (maxText !== maxLength
                ? (formData[name] ?? "").length + " / " + maxLength
                : "")}
          </div>
        </div>
      }
      value={formData[name] ?? ""}
      onChange={(e) => {
        let data = { ...formData };
        if (name === "iglesia" && e.target.value === "7") {
          data["nombre_de_la_iglesia"] = "";
        } else if (name === "alergias" && e.target.value === "S") {
          data["detalle_de_alergia"] = "";
        } else if (name === "patologias" && e.target.value === "S") {
          data["detalle_de_patologia"] = "";
        }
        setFormData({ ...data, [name]: e.target.value });
      }}
    >
      {children}
    </TextField>
  );
};
