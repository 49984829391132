import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Bloodtype,
  Check,
  Church,
  ChurchOutlined,
  Close,
  ContactEmergency,
  Female,
  Instagram,
  LocalHospital,
  Mail,
  Male,
  MedicalServices,
  NotificationImportant,
  PermIdentity,
  Vaccines,
  WhatsApp,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { bloodTypes, dateDiff, genders, getWordGender } from "../utils";
import { CustomTextField, isValidField } from "./CustomTextField";
import { ref, set } from "firebase/database";
import { database } from "../config";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

export const defaultVisibleFields = [
  "cedula",
  "fecha_de_nacimiento",
  "nombres",
  "apellidos",
  "genero",
  "tipo_de_sangre",
  "alergias",
  "detalle_de_alergia",
  "patologias",
  "detalle_de_patologia",
  "medicamentos",
  "celular",
  "correo",
  "celular_emergencias",
  "contacto_emergencias",
  "direccion",
  "tiktok",
  "instagram",
  "iglesia",
  "nombre_de_la_iglesia",
];

export const defaultFormData = (eventID) => {
  return {
    rol: "0",
    eventos: { [eventID]: "0" }, // Como Participante: (0)
    pagos: { [eventID]: false },
    nombres: "",
    apellidos: "",
    genero: "M",
    tipo_de_sangre: "O+",
    alergias: false,
    patologias: false,
    contacto_emergencias: "",
    celular_emergencias: "",
    direccion: "",
    cedula: "",
    fecha_de_nacimiento: new Date(Date.parse("2000-01-02")),
    celular: "",
    correo: "",
    iglesia: "0",
  };
};
export const writeUserData = async (
  formData,
  userId,
  fecha_registro,
  eventID,
  withEvent = true
) => {
  const promesas = [];
  if (withEvent) {
    promesas.push(
      set(
        ref(database, "eventos/" + eventID + "/participantes/" + userId),
        true
      )
    );
  }

  let user_data = {
    ...formData,
    ...(withEvent
      ? {
          eventos: { ...(formData?.eventos || {}), [eventID]: "0" }, // Como Participante: (0)
          pagos: { ...(formData?.pagos || {}), [eventID]: false },
          fechas_de_inscripcion: {
            ...(formData?.fechas_de_inscripcion || {}),
            [eventID]: fecha_registro,
          },
        }
      : {
          ...(formData?.eventos ? { eventos: formData.eventos } : {}),
          ...(formData?.pagos ? { pagos: formData.pagos } : {}),
          ...(formData?.fechas_de_inscripcion
            ? { fechas_de_inscripcion: formData.fechas_de_inscripcion }
            : {}),
        }),
  };

  // Eliminamos props creadas para uso del SISTEMA
  delete user_data.initials;
  delete user_data.name;
  delete user_data.type;
  delete user_data.uid;

  //console.log(user_data);

  promesas.push(set(ref(database, "users/" + userId), user_data));
  await Promise.all(promesas);
};

/**
 *
 * @param {props} props
 * @returns
 */
export const FormularioDatos = ({
  formData,
  setFormData,
  validateForm = false,
  verifyCedula,
  disabledFields = [],
  visibleFields = defaultVisibleFields, // Campos visibles
  iglesias = { 0: "Cargando..." },
  headerBanner,
  footer,
  eventData,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      {headerBanner ? (
        <Grid item xs={12}>
          {headerBanner}
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              "Datos Personales" +
              (formData["cedula"]?.length !== 10 ? " - Primero tu Cédula" : "")
            }
          />
          <Divider />
          <CardContent>
            <Box component="form" autoComplete="off" padding={1.5}>
              <Grid
                container
                columnSpacing={2}
                style={{ marginBottom: "-20px" }}
              >
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    validateField={validateForm}
                    disabled={disabledFields.includes("cedula")}
                    formData={formData}
                    onBlurCallback={verifyCedula}
                    setFormData={setFormData}
                    label="Cédula"
                    inputMode="numeric"
                    placeholder="0987654321"
                    maxLength={10}
                    suffix={<PermIdentity />}
                    leftHelperText="De la persona que asiste"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    disabled={
                      disabledFields.includes("fecha_de_nacimiento") ||
                      formData["cedula"]?.length !== 10
                    }
                    inputFormat="DD/MM/YYYY"
                    label="Fecha de Nacimiento"
                    value={formData["fecha_de_nacimiento"]}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        fecha_de_nacimiento: new Date(Date.parse(value))
                          .toISOString()
                          .substring(0, 10),
                      });
                    }}
                    disableFuture
                    minDate="1925-01-01" //Fecha mínima de nacimiento exagerada
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        required
                        InputProps={{ autoComplete: "bday" }}
                        error={
                          !isValidField(
                            "fecha_de_nacimiento",
                            formData,
                            undefined,
                            [],
                            eventData
                          )
                        }
                        helperText={
                          !isValidField(
                            "fecha_de_nacimiento",
                            formData,
                            undefined,
                            [],
                            eventData
                          )
                            ? "No tienes la edad suficiente :("
                            : `Tienes ${dateDiff(
                                Date.parse(formData["fecha_de_nacimiento"])
                              )} años`
                        }
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    validateField={validateForm}
                    disabled={
                      disabledFields.includes("nombres") ||
                      formData["cedula"]?.length !== 10
                    }
                    formData={formData}
                    autoCapitalize="words"
                    autocomplete="given-name"
                    placeholder="Nombres..."
                    setFormData={setFormData}
                    label="Nombres"
                    leftHelperText="Tus nombres, solo letras"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    validateField={validateForm}
                    disabled={
                      disabledFields.includes("apellidos") ||
                      formData["cedula"]?.length !== 10
                    }
                    formData={formData}
                    autoCapitalize="words"
                    autocomplete="family-name"
                    placeholder="Apellidos..."
                    setFormData={setFormData}
                    label="Apellidos"
                    leftHelperText="Dos apellidos, solo letras"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={
                    defaultVisibleFields.every((f) => visibleFields.includes(f))
                      ? 6
                      : 12
                  }
                >
                  <CustomTextField
                    disabled={
                      disabledFields.includes("genero") ||
                      formData["cedula"]?.length !== 10
                    }
                    validateField={validateForm}
                    select
                    formData={formData}
                    setFormData={setFormData}
                    label="Género"
                    autocomplete="sex"
                    prefix={formData["genero"] === "M" ? <Male /> : <Female />}
                    leftHelperText="Selecciona tu Género"
                  >
                    {genders.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>

                {visibleFields.includes("tipo_de_sangre") ? (
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      validateField={validateForm}
                      disabled={formData["cedula"]?.length !== 10}
                      select
                      formData={formData}
                      setFormData={setFormData}
                      label="Tipo de Sangre"
                      prefix={<Bloodtype />}
                      leftHelperText="Para casos de emergencia"
                    >
                      {bloodTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                ) : (
                  <></>
                )}

                {visibleFields.includes("alergias") ? (
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      validateField={validateForm}
                      disabled={formData["cedula"]?.length !== 10}
                      select
                      formData={formData}
                      setFormData={setFormData}
                      label="Alergias"
                      prefix={formData["alergias"] ? <Check /> : <Close />}
                      leftHelperText={`¿Eres Alérgic${getWordGender(
                        formData["genero"]
                      )}?`}
                    >
                      {[
                        {
                          value: true,
                          name: `Sí soy Alérgic${getWordGender(
                            formData["genero"]
                          )}`,
                        },
                        {
                          value: false,
                          name: `No soy Alérgic${getWordGender(
                            formData["genero"]
                          )}`,
                        },
                      ].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                ) : (
                  <></>
                )}

                {visibleFields.includes("patologias") ? (
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      validateField={validateForm}
                      disabled={formData["cedula"]?.length !== 10}
                      select
                      formData={formData}
                      setFormData={setFormData}
                      label="Patologías"
                      prefix={formData["patologias"] ? <Check /> : <Close />}
                      leftHelperText="¿Alguna Patología Importante?"
                    >
                      {[
                        {
                          value: true,
                          name: `Sí tengo`,
                        },
                        {
                          value: false,
                          name: `No tengo`,
                        },
                      ].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                ) : (
                  <></>
                )}

                {visibleFields.includes("alergias") ? (
                  <Grid item xs={12}>
                    <Collapse
                      in={
                        formData[
                          "alergias"
                        ] /* Si tiene alergias debe detallar */
                      }
                      unmountOnExit
                      timeout={750}
                    >
                      <CustomTextField
                        validateField={validateForm}
                        disabled={formData["cedula"]?.length !== 10}
                        formData={formData}
                        autoCapitalize="words"
                        setFormData={setFormData}
                        label="Detalle de Alergia"
                        suffix={<Vaccines />}
                        leftHelperText="Para casos de emergencia"
                      />
                    </Collapse>
                  </Grid>
                ) : (
                  <></>
                )}

                {visibleFields.includes("patologias") ? (
                  <Grid item xs={12}>
                    <Collapse
                      in={
                        formData["patologias"]
                      } /* Si tiene patologias debe detallar */
                      unmountOnExit
                      timeout={750}
                    >
                      <CustomTextField
                        validateField={validateForm}
                        disabled={formData["cedula"]?.length !== 10}
                        formData={formData}
                        autoCapitalize="words"
                        setFormData={setFormData}
                        label="Detalle de Patologia"
                        suffix={<LocalHospital />}
                        leftHelperText="Para casos de emergencia"
                      />
                    </Collapse>
                  </Grid>
                ) : (
                  <></>
                )}

                {visibleFields.includes("medicamentos") ? (
                  <Grid item xs={12}>
                    <CustomTextField
                      required={false}
                      validateField={validateForm}
                      disabled={formData["cedula"]?.length !== 10}
                      formData={formData}
                      autoCapitalize="words"
                      setFormData={setFormData}
                      label="Medicamento/s"
                      suffix={<MedicalServices />}
                      leftHelperText="¿Necesitas alguno? Recuerda llevarlo!"
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Datos de Contacto" />
          <Divider />
          <CardContent>
            <Box component="form" autoComplete="off" padding={1}>
              <Grid
                container
                columnSpacing={2}
                style={{ marginBottom: "-20px" }}
              >
                {visibleFields.includes("direccion") ? (
                  <Grid item xs={12}>
                    <CustomTextField
                      validateField={validateForm}
                      disabled={formData["cedula"]?.length !== 10}
                      autoCapitalize="words"
                      formData={formData}
                      setFormData={setFormData}
                      label="Dirección"
                      suffix={
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          fontSize={"18px"}
                        />
                      }
                      placeholder="Ciudadela / Urbanización / Barrio"
                      leftHelperText="¿Dónde Vives?"
                      required={true}
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    validateField={validateForm}
                    disabled={
                      disabledFields.includes("celular") ||
                      formData["cedula"]?.length !== 10
                    }
                    formData={formData}
                    setFormData={setFormData}
                    label="Celular"
                    type="tel"
                    autocomplete="tel-national"
                    suffix={<WhatsApp />}
                    placeholder="0987654321"
                    maxLength={10}
                    leftHelperText="Solo se aceptan números"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    validateField={validateForm}
                    disabled={
                      disabledFields.includes("correo") ||
                      formData["cedula"]?.length !== 10
                    }
                    lowercase
                    importantField
                    formData={formData}
                    setFormData={setFormData}
                    label="Correo"
                    type="email"
                    autocomplete="email"
                    suffix={<Mail />}
                    placeholder="correo@ejemplo.com"
                    leftHelperText="Enviaremos tu entrada a este correo ⚠️"
                  />
                </Grid>

                {visibleFields.includes("celular_emergencias") ? (
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      validateField={validateForm}
                      disabled={formData["cedula"]?.length !== 10}
                      formData={formData}
                      setFormData={setFormData}
                      label="Celular Emergencias"
                      type="tel"
                      suffix={<NotificationImportant />}
                      placeholder="0987654321"
                      maxLength={10}
                      leftHelperText="Para casos de emergencia"
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                {visibleFields.includes("contacto_emergencias") ? (
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      validateField={validateForm}
                      disabled={formData["cedula"]?.length !== 10}
                      formData={formData}
                      autoCapitalize="words"
                      suffix={<ContactEmergency />}
                      placeholder="Nombre y Apellido"
                      setFormData={setFormData}
                      label="Contacto Emergencias"
                      leftHelperText="Para casos de emergencia"
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                {visibleFields.includes("tiktok") ? (
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      validateField={validateForm}
                      disabled={formData["cedula"]?.length !== 10}
                      lowercase
                      formData={formData}
                      setFormData={setFormData}
                      label="TikTok"
                      suffix={
                        <FontAwesomeIcon icon={faTiktok} fontSize={"18px"} />
                      }
                      prefix={"@"}
                      placeholder="usuario"
                      required={false}
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                {visibleFields.includes("instagram") ? (
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      validateField={validateForm}
                      disabled={formData["cedula"]?.length !== 10}
                      lowercase
                      formData={formData}
                      setFormData={setFormData}
                      label="Instagram"
                      prefix={"@"}
                      suffix={<Instagram />}
                      placeholder="usuario"
                      required={false}
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={12}>
                  <CustomTextField
                    validateField={validateForm}
                    disabled={formData["cedula"]?.length !== 10}
                    select
                    formData={formData}
                    setFormData={setFormData}
                    label="Iglesia"
                    prefix={<Church />}
                    leftHelperText="Selecciona la iglesia donde asistes"
                  >
                    {Object.keys(iglesias).map((key) => (
                      <MenuItem key={key} value={key}>
                        {iglesias[key]}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>

                <Grid item xs={12}>
                  <Collapse
                    in={formData["iglesia"] === "7" /* "Otra Iglesia" */}
                    unmountOnExit
                    timeout={750}
                  >
                    <CustomTextField
                      validateField={validateForm}
                      disabled={formData["cedula"]?.length !== 10}
                      formData={formData}
                      autoCapitalize="words"
                      setFormData={setFormData}
                      label="Nombre de la Iglesia"
                      suffix={<ChurchOutlined />}
                      leftHelperText="¿Cómo se llama tu Iglesia?"
                    />
                  </Collapse>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {footer}
    </Grid>
  );
};
