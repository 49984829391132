import {
  Avatar,
  Backdrop,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  ImageList,
  ImageListItem,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Zoom,
  alpha,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MainContent } from "../../utils";
import { SiteContext } from "../../contexts/SiteContext";
import { ThemeContext } from "../../theme/ThemeProvider";
import CustomLoading from "../../components/CustomLoading";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
// Import Swiper styles
import "swiper/css/bundle";
import { CalendarMonth, Check, Close, LocationOn } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { themeList } from "../../theme/schemes/Temas";
import Countdown from "react-countdown";
import { Box } from "@mui/system";
import { CustomImage } from "../../components/CustomImage";
import { format } from "date-fns";

export const Eventos = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { eventID } = useParams();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    user,
    eventos = {},
    lugares,
    setEventData,
    defaultEventID,
  } = useContext(SiteContext);
  const [loadingGlobal, setLoadingGlobal] = useState(true);
  const [hoverEventos, setHoverEventos] = useState({});
  const [selectedEvent, setSelectedEvent] = useState();
  const [eventSwiper, setEventSwiper] = useState();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    if (selectedEvent) {
      setEventData(selectedEvent);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (Object.keys(eventos).length > 0) {
      setLoadingGlobal(false);
    }
  }, [eventos]);

  useEffect(() => {
    if (eventSwiper) {
      eventSwiper.scrollbar.el.style = "opacity: 0";
      const local_eventID = eventID ?? user.lastEventID ?? defaultEventID;
      setSelectedEvent(eventos[local_eventID]);
      eventSwiper.slideTo(local_eventID);
    }
  }, [eventSwiper, eventID]);

  return (
    <>
      {loadingGlobal ? (
        <CustomLoading />
      ) : (
        <MainContent header_height={theme.header.height}>
          <Swiper
            style={{
              width: "100%",
              height: "inherit",
              padding: "85px 20px 25px 20px",
            }}
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={85}
            slidesPerView={"auto"}
            centeredSlides={true}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{
              draggable: true,
            }}
            onSwiper={(sw) => setEventSwiper(sw)}
            onSlideChange={(sw) => {
              setSelectedEvent(Object.values(eventos)[sw.activeIndex]);
            }}
          >
            {Object.keys(eventos)
              .map((k) => ({ ...eventos[k], id: k }))
              .filter(
                (eventData) =>
                  (eventData.estado && eventData.isActive) ||
                  (eventData?.participantes || {})[user.uid] !== undefined
                // Solo se pueden ver eventos en los que se haya participado o eventos actualmente activos
              )
              .map((eventData, j) => (
                <SwiperSlide
                  key={j}
                  style={{
                    maxWidth: "85%",
                    height: "100%",
                  }}
                >
                  <>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 2000,
                        top: "-35px",
                        left: 0,
                        right: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          zIndex: 2000,
                          top: "-20px",
                          left: 0,
                          right: 0,
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "fit-content",
                        }}
                      >
                        <Chip
                          sx={{
                            bgcolor: "white",
                            fontSize: "15px",
                            [theme.breakpoints.down("sm")]: {
                              fontSize: "11px",
                            },
                            padding: "7px",
                            height: "fit-content",
                            fontFamily: "Golos Text",
                          }}
                          variant="outlined"
                          color="primary"
                          label={
                            eventData.isActive
                              ? eventData.isHappening
                                ? "¡El evento ha iniciado!"
                                : "El evento iniciará dentro de"
                              : "El evento ha finalizado"
                          }
                        />
                      </div>
                      <Paper
                        sx={{
                          bgcolor: themeList.find(
                            (t) => t.id === localStorage.getItem("appTheme")
                          ).colors.darkBG,
                          color: theme.palette.primary.light,
                          padding: "10px 20px 0px 20px",
                          [theme.breakpoints.down("sm")]: {
                            padding: "5px 20px 0px 20px",
                          },
                          borderRadius: "40px",
                          border: "5px solid " + theme.palette.secondary.light,
                        }}
                        elevation={0}
                      >
                        <Countdown
                          date={eventData.fecha_inicio}
                          intervalDelay={0}
                          precision={3}
                          renderer={(props) => (
                            <Box
                              sx={{
                                ".MuiTypography-root": {
                                  fontFamily: "Bebas Neue",
                                  fontSize: "5vw",
                                  color: theme.palette.primary.light,
                                  [theme.breakpoints.down("lg")]: {
                                    fontSize: "5.5vw",
                                  },
                                  [theme.breakpoints.down("sm")]: {
                                    fontSize: "7vw",
                                  },
                                  ".countdown-label": {
                                    marginLeft: "5px",
                                    color: "white",
                                    [theme.breakpoints.up("md")]: {
                                      fontSize: "2vw",
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                      fontSize: "11px",
                                    },
                                  },
                                },
                              }}
                            >
                              <table cellPadding={5}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <Typography>
                                        {props.days.toLocaleString("es-ES", {
                                          minimumIntegerDigits: 2,
                                        })}
                                        <Typography
                                          component={"span"}
                                          className="countdown-label"
                                        >
                                          Días
                                        </Typography>
                                      </Typography>
                                    </td>
                                    <td>
                                      <Typography>
                                        {props.hours.toLocaleString("es-ES", {
                                          minimumIntegerDigits: 2,
                                        })}
                                        <Typography
                                          component={"span"}
                                          className="countdown-label"
                                        >
                                          Horas
                                        </Typography>
                                      </Typography>
                                    </td>
                                    <td>
                                      <Typography>
                                        {props.minutes.toLocaleString("es-ES", {
                                          minimumIntegerDigits: 2,
                                        })}
                                        <Typography
                                          component={"span"}
                                          className="countdown-label"
                                        >
                                          Min.
                                        </Typography>
                                      </Typography>
                                    </td>
                                    <td>
                                      <Typography>
                                        {props.seconds.toLocaleString("es-ES", {
                                          minimumIntegerDigits: 2,
                                        })}
                                        <Typography
                                          component={"span"}
                                          className="countdown-label"
                                        >
                                          Seg.
                                        </Typography>
                                      </Typography>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Box>
                          )}
                        />
                      </Paper>
                    </div>
                    <Card
                      onMouseEnter={() =>
                        setHoverEventos({
                          ...hoverEventos,
                          [eventData.id]: true,
                        })
                      }
                      onMouseLeave={() =>
                        setHoverEventos({
                          ...hoverEventos,
                          [eventData.id]: false,
                        })
                      }
                      sx={{
                        filter:
                          eventData.isActive === true
                            ? "inherit"
                            : "grayscale(100%)",
                        textAlign: "center",
                      }}
                    >
                      <CardMedia
                        component={"div"}
                        style={{ position: "relative" }}
                      >
                        <ImageList
                          sx={{ m: 0 }}
                          variant="quilted"
                          cols={isSM ? 2 : 3}
                          rowHeight={windowSize.height * (isSM ? 0.175 : 0.25)}
                          gap={0}
                        >
                          {Object.values(eventData?.fotos || []).map(
                            (item, i) => (
                              <ImageListItem key={i}>
                                <CustomImage
                                  alt={`${eventData.nombre} (${i})`}
                                  src={item}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    display: "block",
                                  }}
                                />
                              </ImageListItem>
                            )
                          )}
                        </ImageList>
                        <Backdrop
                          sx={{
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            position: "absolute",
                            backgroundColor: alpha(
                              theme.colors.alpha.white[100],
                              0.65
                            ),
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: "60px",
                          }}
                          open={hoverEventos[eventData.id] || false}
                        >
                          <Zoom in={hoverEventos[eventData.id] || false}>
                            <img
                              src={
                                eventData.logo /* USAR LOGO SVG (.logoSVG) */
                              }
                              width="auto"
                              style={{ maxHeight: "20vh", maxWidth: "65%" }}
                            />
                          </Zoom>
                          <Zoom
                            unmountOnExit
                            in={hoverEventos[eventData.id] || false}
                            style={{ transitionDelay: "500ms" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "5px",
                                marginTop: "10px",
                                marginBottom: "15px",
                                [theme.breakpoints.down("sm")]: {
                                  fontSize: "11px",
                                  gap: "2.5px",
                                },
                                ".MuiSvgIcon-root": {
                                  [theme.breakpoints.down("sm")]: {
                                    fontSize: "15px",
                                  },
                                },
                                ".EventDataSeparator": {
                                  margin: "-15px 10px 0px 10px",
                                  fontSize: "30px",
                                  opacity: 0.5,
                                  [theme.breakpoints.down("sm")]: {
                                    margin: "-5px 5px 0px 5px",
                                    fontSize: "15px",
                                  },
                                },
                              }}
                            >
                              <LocationOn />
                              <a
                                style={{ color: theme.colors.alpha.black[100] }}
                                href={
                                  lugares[eventData?.lugar]?.url_ubicacion ??
                                  "#"
                                }
                                target="_blank"
                              >
                                {lugares[eventData?.lugar]?.nombre_del_lugar}
                              </a>
                              <span className="EventDataSeparator">|</span>
                              <CalendarMonth />
                              <span style={{ marginLeft: "5px" }}>
                                {format(
                                  new Date(eventData?.fecha_inicio),
                                  "dd 'de' MMM 'a las' HH:mm"
                                )}
                              </span>
                            </Box>
                          </Zoom>
                          <Zoom
                            unmountOnExit
                            in={eventData.estado && hoverEventos[eventData.id]}
                            style={{ transitionDelay: "500ms" }}
                          >
                            {eventData.estado &&
                            (eventData?.participantes || {})[user.uid] ===
                              undefined ? (
                              <Button
                                variant="contained"
                                onClick={() => {
                                  navigate(`/registro/${eventData.id}`);
                                }}
                              >
                                ¡Quiero Inscribirme!
                              </Button>
                            ) : (
                              <div></div>
                            )}
                          </Zoom>
                        </Backdrop>
                      </CardMedia>
                      <CardContent>
                        <Typography gutterBottom variant="h2" component="div">
                          {eventData.nombre}
                        </Typography>
                        <Typography variant="h4" color="text.secondary">
                          {eventData.descripcion}
                        </Typography>
                      </CardContent>
                    </Card>
                    <div
                      style={{
                        position: "absolute",
                        right: "-20px",
                        bottom: "-20px",
                      }}
                    >
                      <Tooltip
                        arrow
                        placement="left"
                        title={
                          eventData.isActive
                            ? user.eventos && user.eventos[eventData.id]
                              ? "Estas inscrito"
                              : "No estas inscrito"
                            : user.asistencias && user.asistencias[eventData.id]
                            ? "Asististe al evento"
                            : "No asististe al evento"
                        }
                      >
                        <Avatar
                          sx={{
                            width: 50,
                            height: 50,
                            bgcolor: ((eventData?.participantes || {})[user.uid]
                              ? theme.palette.success
                              : theme.palette.error)[
                              eventData.isActive ? "main" : "dark"
                            ],
                          }}
                        >
                          {(eventData?.participantes || {})[user.uid] ? (
                            <Check />
                          ) : (
                            <Close />
                          )}
                        </Avatar>
                      </Tooltip>
                    </div>
                  </>
                </SwiperSlide>
              ))}
            {/* <TabList
              onChange={(evt, newValue) => setSelectedEvent(newValue)}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              {Object.keys(eventos)
                .map((k) => ({ ...eventos[k], id: k }))
                .map((eventData, j) => (
                  <Tab value={eventData.id} key={j} label={eventData.nombre} />
                ))}
            </TabList> */}
          </Swiper>
        </MainContent>
      )}
    </>
  );
};
