import { Box } from "@mui/material";
import ChangeTheme from "./ChangeTheme";

function HeaderButtons() {
  return (
    <Box sx={{ mr: 1 }}>
      {/* <HeaderSearch /> */}
      {/* <ChangeTheme /> */}
      {/* <Box sx={{ mx: 0.5 }} component="span">
        <HeaderNotifications />
      </Box> */}
    </Box>
  );
}

export default HeaderButtons;
