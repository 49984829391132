import {
  Add,
  Block,
  Close,
  GroupAdd,
  Instagram,
  Logout,
  Man,
  Wc,
  WhatsApp,
  Woman,
} from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ref, remove, set } from "firebase/database";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomLoading from "../../components/CustomLoading";
import { database } from "../../config";
import { SiteContext } from "../../contexts/SiteContext";
import { dateDiff, getWordGender, MainContent } from "../../utils";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { Box } from "@mui/system";

const puestosOcupados = 2;

export const Dormitorio = () => {
  const theme = useTheme();
  const { eventID } = useParams();
  const [loading, setLoading] = useState(true);
  const {
    user,
    users,
    eventos,
    lugares,
    dormitoriosAsignados = {},
    eventData,
    setEventData,
    setEventParticipantsGetter,
  } = useContext(SiteContext);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({});

  useEffect(() => {
    if (lugares && Object.keys(dormitoriosAsignados).length > 0) {
      setLoading(false);
    }
  }, [lugares, dormitoriosAsignados]);

  useEffect(() => {
    if (eventos) {
      setEventData(eventos[eventID]);
      setEventParticipantsGetter(eventID);
    }
  }, [eventos, eventID]);

  const handleJoinRoom = async (habitacion, userUID) => {
    setLoading(true);
    await Promise.all([
      set(
        ref(database, `users/${userUID}/habitaciones/${eventID}`),
        habitacion.id
      ),
      set(
        ref(
          database,
          `dormitoriosAsignados/${eventID}/${habitacion.id}/${userUID}`
        ),
        true
      ),
    ]);
    setLoading(false);
  };

  const handleLeaveRoom = async (habitacion, userUID) => {
    setLoading(true);
    await Promise.all([
      remove(ref(database, `users/${userUID}/habitaciones/${eventID}`)),
      remove(
        ref(
          database,
          `dormitoriosAsignados/${eventID}/${habitacion.id}/${userUID}`
        )
      ),
    ]);
    setLoading(false);
  };

  return (
    <>
      <CustomLoading show={loading} />
      <MainContent header_height={theme.header.height}>
        <div
          style={{
            overflow: "auto",
            maxHeight: "100%",
            width: "100%",
            padding: "15px",
            textAlign: "center",
          }}
        >
          {lugares ? (
            <>
              <Grid container padding={1} paddingBottom={2} rowGap={0.25}>
                <Grid item xs={12} textAlign={"center"}>
                  <Typography
                    variant="h1"
                    color={"secondary"}
                    style={{
                      fontFamily: "Bebas Neue",
                      letterSpacing: "5px",
                      fontSize: "50px",
                    }}
                  >
                    Dormitorios
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    fontSize={"15px"}
                    display="flex"
                    justifyContent="center"
                  >
                    Sujeto a cambios de ser necesario ⚠️
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontSize={"15px"}
                    display="flex"
                    justifyContent="center"
                  >
                    {user?.habitaciones && user?.habitaciones[eventID]
                      ? "¡Ponte en contacto con tus compañeros!"
                      : "Si no lo escoges a tiempo, te asignaremos uno"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                padding={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {Object.keys(lugares[eventData?.lugar]?.habitaciones || {})
                  .map((key) => ({
                    id: key,
                    ...lugares[eventData?.lugar].habitaciones[key],
                  }))
                  .sort((a, b) => (a?.orden >= b?.orden ? 1 : -1))
                  .filter(
                    (hab) =>
                      user.rol === "admin" ||
                      ["ALL", user.genero].includes(hab.genero_admitido)
                  )
                  .map((habitacion, i) => (
                    <Grid item xs={12} lg={5} xl={4} key={i}>
                      <Card>
                        {/* <CardMedia
                        sx={{ height: 140 }}
                        image="/static/images/cards/contemplative-reptile.jpg"
                        title="green iguana"
                        /> */}
                        <CardHeader
                          title={
                            <Box
                              display={"flex"}
                              flexDirection={"row"}
                              justifyContent="center"
                              alignItems={"center"}
                            >
                              {habitacion.nombre_de_la_habitacion +
                                (dormitoriosAsignados[eventID] &&
                                dormitoriosAsignados[eventID][habitacion.id] &&
                                dormitoriosAsignados[eventID][habitacion.id][
                                  user.uid
                                ]
                                  ? " - Asignado"
                                  : "")}
                              <Box sx={{ marginLeft: "5px", marginTop: "2px" }}>
                                {habitacion.genero_admitido === "ALL" ? (
                                  <Wc />
                                ) : habitacion.genero_admitido === "M" ? (
                                  <Man />
                                ) : (
                                  <Woman />
                                )}
                              </Box>
                            </Box>
                          }
                          style={{
                            padding: "10px",
                            background:
                              dormitoriosAsignados[eventID] &&
                              dormitoriosAsignados[eventID][habitacion.id] &&
                              dormitoriosAsignados[eventID][habitacion.id][
                                user.uid
                              ]
                                ? theme.palette.terciary.main
                                : theme.colors.alpha.black[30],
                          }}
                        />
                        <CardContent style={{ paddingBottom: "0px" }}>
                          <Typography variant="body2" color="text.secondary">
                            {Object.keys(
                              dormitoriosAsignados[eventID]
                                ? dormitoriosAsignados[eventID][
                                    habitacion.id
                                  ] ?? {}
                                : {}
                            ).length +
                              ` / ${
                                habitacion.capacidad_maxima -
                                (user.rol !== "admin" ? puestosOcupados : 0)
                              } Integrantes`}
                          </Typography>
                          {user.rol === "admin" ||
                          (Object.keys(dormitoriosAsignados[eventID] ?? {})
                            .length > 0 &&
                            dormitoriosAsignados[eventID][habitacion.id]) ? (
                            <List
                              disablePadding
                              sx={{
                                overflow: "auto",
                                maxHeight: 250,
                                minHeight: 250,
                              }}
                            >
                              {dormitoriosAsignados[eventID] &&
                                Object.keys(
                                  dormitoriosAsignados[eventID][
                                    habitacion.id
                                  ] ?? {}
                                )
                                  .sort((a, b) =>
                                    users[a]?.consejerias &&
                                    users[a]?.consejerias[eventID] === true
                                      ? -1
                                      : 1
                                  )
                                  .map((userUID, j) => (
                                    <ListItem
                                      divider
                                      key={j}
                                      style={{
                                        paddingLeft: "5px",
                                      }}
                                      secondaryAction={
                                        <>
                                          {user?.habitaciones &&
                                          user.habitaciones[eventID] ===
                                            habitacion.id ? (
                                            <>
                                              <IconButton
                                                size="small"
                                                edge="end"
                                                href={`https://api.whatsapp.com/send?phone=593${users[
                                                  userUID
                                                ]?.celular?.substring(
                                                  1
                                                )}&text=Hola%20${
                                                  (
                                                    users[userUID]?.nombres ||
                                                    ""
                                                  ).split(" ")[0]
                                                }! 👋🏻`}
                                                target={"_blank"}
                                              >
                                                <WhatsApp />
                                              </IconButton>
                                              <IconButton
                                                size="small"
                                                edge="end"
                                                disabled={
                                                  users[userUID]?.instagram ===
                                                    undefined ||
                                                  users[userUID]?.instagram ===
                                                    ""
                                                }
                                                style={{ marginLeft: "5px" }}
                                                href={
                                                  "https://www.instagram.com/" +
                                                  users[userUID]?.instagram
                                                }
                                                target={"_blank"}
                                              >
                                                <Instagram />
                                              </IconButton>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {user.rol === "admin" ? (
                                            <IconButton
                                              size="small"
                                              edge="end"
                                              style={{ marginLeft: "5px" }}
                                              onClick={() =>
                                                handleLeaveRoom(
                                                  habitacion,
                                                  userUID
                                                )
                                              }
                                            >
                                              <Close />
                                            </IconButton>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      }
                                      sx={{
                                        paddingRight:
                                          user?.habitaciones &&
                                          user.habitaciones[eventID] ===
                                            habitacion.id
                                            ? "calc((48px * 2) " +
                                              (user.rol === "admin"
                                                ? "+ 20px"
                                                : "- 15px") +
                                              ")"
                                            : "48px",
                                        ".MuiTypography-root": {
                                          maxWidth: "100%",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        },
                                        ".MuiListItemSecondaryAction-root": {
                                          right: "10px",
                                        },
                                      }}
                                    >
                                      <ListItemAvatar>
                                        <Avatar
                                          sx={{
                                            color: "white",
                                            fontWeight:
                                              users[userUID]?.consejerias &&
                                              users[userUID]?.consejerias[
                                                eventID
                                              ]
                                                ? "bolder"
                                                : "inherit",
                                            bgcolor:
                                              user?.habitaciones &&
                                              user.habitaciones[eventID] ===
                                                habitacion.id
                                                ? theme.palette[
                                                    users[userUID]
                                                      ?.consejerias &&
                                                    users[userUID]?.consejerias[
                                                      eventID
                                                    ] === true
                                                      ? "primary"
                                                      : "terciary"
                                                  ].main
                                                : theme.colors.alpha.black[30],
                                          }}
                                        >
                                          {users[userUID]?.initials ?? "N/A"}
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={
                                          <span
                                            style={{
                                              fontWeight:
                                                users[userUID]?.consejerias &&
                                                users[userUID]?.consejerias[
                                                  eventID
                                                ]
                                                  ? "bolder"
                                                  : "inherit",
                                            }}
                                          >
                                            {(users[userUID]?.nombres ?? "") +
                                              " " +
                                              (users[userUID]?.apellidos ??
                                                "") +
                                              (user.uid === userUID
                                                ? " (Tú)"
                                                : user.rol === "admin"
                                                ? " (" +
                                                  dateDiff(
                                                    new Date(
                                                      users[userUID]
                                                        ?.fecha_de_nacimiento +
                                                        "T00:00:00.000"
                                                    )
                                                  ) +
                                                  " años)"
                                                : "") +
                                              (users[userUID]?.consejerias &&
                                              users[userUID]?.consejerias[
                                                eventID
                                              ]
                                                ? ` (Consejer${getWordGender(
                                                    users[userUID].genero
                                                  )})`
                                                : "")}
                                          </span>
                                        }
                                      />
                                    </ListItem>
                                  ))}
                              {user.rol === "admin" ? (
                                <ListItem
                                  divider
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <Autocomplete
                                    fullWidth
                                    clearOnEscape
                                    ListboxProps={{
                                      style: {
                                        height: "200px",
                                      },
                                    }}
                                    value={
                                      autoCompleteOptions[habitacion.id] ?? null
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.uid === value.uid
                                    }
                                    onChange={(event, newValue) => {
                                      setAutoCompleteOptions({
                                        ...autoCompleteOptions,
                                        [habitacion.id]: newValue ?? null,
                                      });
                                    }}
                                    renderOption={(
                                      props,
                                      option,
                                      { inputValue }
                                    ) => {
                                      const matches = match(
                                        option.label,
                                        inputValue,
                                        { insideWords: true }
                                      );
                                      const parts = parse(
                                        option.label,
                                        matches
                                      );
                                      return (
                                        <li {...props}>
                                          <div>
                                            {parts.map((part, index) => (
                                              <span
                                                key={index}
                                                style={{
                                                  fontWeight: part.highlight
                                                    ? 700
                                                    : 400,
                                                }}
                                              >
                                                {part.text}
                                              </span>
                                            ))}
                                          </div>
                                        </li>
                                      );
                                    }}
                                    options={Object.keys(users ?? {})
                                      .filter(
                                        (uid) =>
                                          users[uid].eventos &&
                                          users[uid].eventos[eventID] &&
                                          ["ALL", users[uid]?.genero].includes(
                                            habitacion.genero_admitido
                                          ) &&
                                          Object.keys(
                                            dormitoriosAsignados[eventID] ?? {}
                                          ).every((habID) =>
                                            Object.keys(
                                              dormitoriosAsignados[eventID][
                                                habID
                                              ]
                                            ).every(
                                              (everyUID) => everyUID !== uid
                                            )
                                          )
                                      )
                                      .map((uid) => ({
                                        uid,
                                        label:
                                          users[uid].nombres +
                                          " " +
                                          users[uid].apellidos +
                                          ` - ${dateDiff(
                                            new Date(
                                              users[uid]?.fecha_de_nacimiento +
                                                "T00:00:00.000"
                                            )
                                          )} años`,
                                      }))
                                      .sort((a, b) =>
                                        a.label.toLowerCase() >
                                        b.label.toLowerCase()
                                          ? 1
                                          : -1
                                      )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        placeholder="Agregar Miembro..."
                                      />
                                    )}
                                  />
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    sx={{
                                      marginLeft: "5px",
                                    }}
                                    onClick={() => {
                                      if (autoCompleteOptions[habitacion.id]) {
                                        handleJoinRoom(
                                          habitacion,
                                          autoCompleteOptions[habitacion.id].uid
                                        );
                                        setAutoCompleteOptions({
                                          ...autoCompleteOptions,
                                          [habitacion.id]: undefined,
                                        });
                                      }
                                    }}
                                  >
                                    <Add />
                                  </IconButton>
                                </ListItem>
                              ) : (
                                <></>
                              )}
                            </List>
                          ) : (
                            <div
                              style={{
                                height: "250px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Aún no hay nadie...
                            </div>
                          )}
                        </CardContent>
                        <CardActions style={{ padding: "15px" }}>
                          {user.rol === "admin" ||
                          (((eventData?.abonoMinimoParaDormitorios ?? 0) ===
                            0 ||
                            (user.abonos &&
                              user.abonos[eventID] &&
                              user.abonos[eventID] >=
                                (eventData?.abonoMinimoParaDormitorios ?? 0)) ||
                            (user.pagos && user.pagos[eventID] === true)) && // Para los que van por canje/free/descuento
                            eventData.allowEditHabitaciones === true) ? (
                            dormitoriosAsignados[eventID] &&
                            dormitoriosAsignados[eventID][habitacion.id] &&
                            dormitoriosAsignados[eventID][habitacion.id][
                              user.uid
                            ] ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() =>
                                  handleLeaveRoom(habitacion, user.uid)
                                }
                              >
                                Abandonar Dormitorio
                                <Logout style={{ marginLeft: "10px" }} />
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                fullWidth
                                disabled={
                                  (user.habitaciones &&
                                    user.habitaciones[eventID] !== undefined) ||
                                  Object.keys(
                                    dormitoriosAsignados[eventID]
                                      ? dormitoriosAsignados[eventID][
                                          habitacion.id
                                        ] ?? {}
                                      : {}
                                  ).length +
                                    1 >
                                    habitacion.capacidad_maxima -
                                      (user.rol !== "admin"
                                        ? puestosOcupados
                                        : 0)
                                }
                                onClick={() =>
                                  handleJoinRoom(habitacion, user.uid)
                                }
                              >
                                ¡Quiero Unirme!
                                <GroupAdd style={{ marginLeft: "10px" }} />
                              </Button>
                            )
                          ) : (
                            <Button
                              variant="contained"
                              fullWidth
                              disabled={true}
                            >
                              {eventData.allowEditHabitaciones === true
                                ? `Realiza un abono${
                                    eventData?.abonoMinimoParaDormitorios
                                      ? ` de $${eventData.abonoMinimoParaDormitorios}`
                                      : ""
                                  } para escoger`
                                : (new Date(eventData.fecha_inicio) > new Date()
                                    ? "Aún"
                                    : "Ya") + " no puedes escoger dormitorio"}
                              <Block style={{ marginLeft: "5px" }} />
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </>
          ) : (
            "..."
          )}
        </div>
      </MainContent>
    </>
  );
};
