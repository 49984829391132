import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Card,
  Typography,
  Container,
  Button,
  useTheme,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { MainContent } from "../utils";
import * as SVGInject from "@iconfu/svg-inject";
import styled from "styled-components";
import { useContext, useEffect } from "react";
import { SiteContext } from "../contexts/SiteContext";

const StyledSVG = styled(Box)(
  ({ theme }) => `
    .primary-color{ 
        fill: ${theme.palette.primary.main}; 
    }
    .primary-dark-color{ 
        fill: ${theme.palette.primary.dark}; 
    }
    .secondary-color{
        fill: ${theme.palette.secondary.main};
    }
    .secondary-dark-color{
      fill: ${theme.palette.secondary.dark};
    }
    .skin-color{
        fill: #E4BCB0;
    }
    .dark-color{
        fill: #383838;
    }`
);

const MessagePage = ({
  errorCode = "404", //https://undraw.co/search
  errorMessage = "La página que buscas no existe.",
  errorSubMessage = "Si esto no debería estar aqui intenta contactarte con el staff :(",
  buttonText = "Volver al Formulario",
  buttonAction,
  buttonIcon = <ArrowBack />,
  buttonText2,
  buttonAction2,
  buttonIcon2,
  isInnerPage = false,
}) => {
  const { defaultEventID } = useContext(SiteContext);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    SVGInject(document.querySelectorAll("img.injectable"));
  }, []);

  return (
    <>
      <Helmet>
        <title>Status - {errorCode + ""}</title>
      </Helmet>
      <MainContent
        header_height={isInnerPage ? theme.header.height : undefined}
        style={{ overflow: "auto" }}
      >
        <Container maxWidth="md" style={{ padding: "25px" }}>
          <StyledSVG textAlign="center" theme={theme}>
            <img
              alt={errorCode}
              style={{ width: "80%", maxWidth: "350px" }}
              src={`/static/images/status/${errorCode}.svg`}
              className="injectable"
            />
          </StyledSVG>
          <Container maxWidth="sm">
            <Card sx={{ textAlign: "center", mt: 3, p: 4 }} elevation={14}>
              {/* <FormControl variant="outlined" fullWidth>
                <OutlinedInputWrapper
                  type="text"
                  placeholder="Search terms here..."
                  endAdornment={
                    <InputAdornment position="end">
                      <ButtonSearch variant="contained" size="small">
                        Search
                      </ButtonSearch>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Divider sx={{ my: 4 }}>OR</Divider> */}
              <Typography variant="h2" sx={{ mb: 2 }}>
                {errorMessage}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{ mb: 2 }}
              >
                {errorSubMessage}
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  if (buttonAction) {
                    buttonAction(navigate);
                  } else {
                    navigate("/registro/" + defaultEventID, { replace: true });
                  }
                }}
              >
                {buttonIcon}
                <div style={{ width: "5px" }} />
                {buttonText}
              </Button>
              {buttonAction2 ? (
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => {
                    if (buttonAction2) {
                      buttonAction2();
                    } else {
                      navigate("/registro/" + defaultEventID, {
                        replace: true,
                      });
                    }
                  }}
                >
                  {buttonIcon2}
                  <div style={{ width: "5px" }} />
                  {buttonText2}
                </Button>
              ) : (
                <></>
              )}
            </Card>
          </Container>
        </Container>
      </MainContent>
    </>
  );
};

export default MessagePage;
